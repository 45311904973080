<mat-card appearance="outlined">
  <mat-card-title>
    <span class="edit-screen-card-title"
          [translate]="'clients.address'">ID card data</span>
  </mat-card-title>
  <mat-card-content class="remove-mat-form-field-wrapper">
    <form [formGroup]="profileDataForm"
          [itfgPermissions]="['USER_WRITE']">
      <div layout="column">
        <div flex
             layout="row"
             layout-align="space-between stretch"
             class="column-gap-10"
             *ngxPermissionsOnly="['USER_ID_DATA_READ']">
          <mat-form-field flex="40">
            <input matInput
                   itfgTrimInput
                   type="text"
                   formControlName="idCardNumber"
                   #idCardNumber
                   placeholder="{{ 'global.number' | translate }}" />
            <mat-label>{{ 'global.number' | translate }}</mat-label>
            <mat-error *ngIf="profileDataForm.controls['idCardNumber'].errors"
                       [translate]="'forms.invalidFormat'">Invalid
              format
            </mat-error>
          </mat-form-field>
          <mat-form-field class="edit-blacklist-aditional-padding"
          [ngStyle]="{'flex': (idCardNumber.value && clientIdVerification) ? '40' : '50'}">
            <input matInput
                   readonly
                   formControlName="idCardIssuedDate"
                   [matDatepicker]="picker"
                   (click)="picker.open()"
                   placeholder="{{ dateIssuedLabel}}">
            <mat-label>{{ dateIssuedLabel}}</mat-label>
            <mat-datepicker-toggle matSuffix
                                   [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker [touchUi]="false"
                            #picker></mat-datepicker>
          </mat-form-field>
          <div>
            <ng-container *ngIf="clientId && idCardNumber.value && clientIdVerification" layout="row" layout-align="center center">
              <button mat-icon-button
                      class="main-button"
                      [matMenuTriggerFor]="idVerificationOptionsMenu"
                      (menuOpened)="idCardVerificationOptionMenuOpened()"
                      matTooltip="{{'global.' + clientIdVerification?.name.toLowerCase() | translate}}"
                      matTooltipPosition="above">
                <mat-icon class="status-icon"
                          [ngStyle]="{'color': idCardVerificationStyleMap[clientIdVerification?.name]?.color}">
                  {{ idCardVerificationStyleMap[clientIdVerification?.name]?.icon }}
                </mat-icon>
              </button>
            </ng-container>
            <mat-menu #idVerificationOptionsMenu="matMenu"
                      class="id-card-verification-menu"
                      [overlapTrigger]="false">
              <div layout="column"
                   layout-align="start start">
                <button mat-icon-button
                        *ngFor="let verificationOption of menuIdCardVerificationOptionsList"
                        [ngStyle]="{'color': idCardVerificationStyleMap[verificationOption.name]?.color}"
                        (click)="verificationOptionChanged(verificationOption)"
                        matTooltip="{{'global.' + verificationOption.name.toLowerCase() | translate}}"
                        matTooltipPosition="right">
                  <mat-icon class="">{{ idCardVerificationStyleMap[verificationOption.name]?.icon }}</mat-icon>
                </button>
              </div>
            </mat-menu>
          </div>
        </div>
        <mat-form-field flex
                        *ngxPermissionsOnly="['USER_ID_DATA_READ']">
          <mat-select matInput
                      placeholder="{{'clients.issuedBy' | translate}}"
                      formControlName="idCardIssuerID">
            <mat-option [disabled]="(['USER_WRITE'] | isUnauthorized | async)"
                        *ngFor="let policeDep of policeDepartments"
                        [value]="policeDep.id">
              {{ policeDep.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field flex>
          <input matInput
                 type="text"
                 formControlName="idCardCity"
                 [matAutocomplete]="idCity"
                 itfgSelectFirstAutocompleteOptionOnBlur
                 placeholder="{{ 'clients.residentCityByIdCard' | translate }}" />
          <mat-label>{{ 'clients.residentCityByIdCard' | translate }}</mat-label>
          <mat-error *ngIf="profileDataForm.get('idCardCity')?.hasError('lacksLatin')"
                     [translate]="'forms.fieldShouldNotHaveLatinCharacters'"></mat-error>
          <mat-error *ngIf="profileDataForm.get('idCardCity')?.hasError('notValidCity') && !(profileDataForm.get('idCardCity')?.hasError('lacksLatin'))"
                     [translate]="'forms.notValidCity'"></mat-error>
          <mat-autocomplete #idCity="matAutocomplete"
                            itfgHighlightFirstAutocompleteOptionOnChange
                            [displayWith]="mapCityToDisplayValue">
            <mat-option *ngFor="let city of idCityList"
                        [value]="city">
              {{ city | cityDisplayText }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
        <mat-form-field flex>
          <input matInput
                 itfgTrimInput
                 type="text"
                 formControlName="idCardAddressName"
                 placeholder="{{ 'clients.addressByIdCard' | translate }}" />
          <mat-label>{{ 'clients.addressByIdCard' | translate }}</mat-label>
          <mat-hint [ngStyle]="{color: 'red' }"
                    *ngIf="profileDataForm.hasError('cityRequiredForAddress')"
                    [translate]="'forms.addressRequiresCity'">Address requires city
          </mat-hint>
          <button matSuffix
                  matTooltip="{{ 'global.openWithGoogleMaps' | translate }}"
                  matTooltipPosition="above"
                  *ngIf="profileDataForm.get('idCardAddressName').value"
                  mat-icon-button
                  tabindex="-1"
                  (click)="openLocationMapForIdCardAddress()">
            <mat-icon>place</mat-icon>
          </button>
        </mat-form-field>
        <mat-form-field>
          <input matInput
                 type="text"
                 formControlName="currentCity"
                 [matAutocomplete]="currentCity"
                 itfgSelectFirstAutocompleteOptionOnBlur
                 placeholder="{{ 'clients.currentResidentCity' | translate }}" />
          <mat-label>{{ 'clients.currentResidentCity' | translate }}</mat-label>
          <mat-error *ngIf="profileDataForm.get('currentCity')?.hasError('lacksLatin')"
                     [translate]="'forms.fieldShouldNotHaveLatinCharacters'"></mat-error>
          <mat-error *ngIf="profileDataForm.get('currentCity')?.hasError('notValidCity') && !(profileDataForm.get('currentCity')?.hasError('lacksLatin'))"
                     [translate]="'forms.notValidCity'"></mat-error>
          <mat-autocomplete #currentCity="matAutocomplete"
                            itfgHighlightFirstAutocompleteOptionOnChange
                            [displayWith]="mapCityToDisplayValue">
            <mat-option *ngFor="let city of currentCityList"
                        [value]="city">
              {{ city | cityDisplayText }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
        <mat-form-field>
          <input matInput
                 itfgTrimInput
                 type="text"
                 formControlName="currentAddressName"
                 placeholder="{{ 'clients.currentAddress' | translate }}" />
          <mat-label>{{ 'clients.currentAddress' | translate }}</mat-label>
          <mat-hint [ngStyle]="{color: 'red' }"
                    *ngIf="profileDataForm.hasError('currentCityRequiredForAddress')"
                    [translate]="'forms.addressRequiresCity'">Address requires city
          </mat-hint>
          <button matSuffix
                  matTooltip="{{ 'global.openWithGoogleMaps' | translate }}"
                  matTooltipPosition="above"
                  *ngIf="profileDataForm.get('currentAddressName').value"
                  mat-icon-button
                  tabindex="-1"
                  (click)="openLocationMapForCurrentAddress()">
            <mat-icon>place</mat-icon>
          </button>
        </mat-form-field>
      </div>
    </form>
  </mat-card-content>
</mat-card>
