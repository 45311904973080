import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  OnDestroy,
  ViewContainerRef,
} from '@angular/core';
import {
  of as observableOf,
  forkJoin as observableForkJoin,
  Observable,
  of,
  Subject,
} from 'rxjs';
import {
  share,
  mergeMap,
  filter,
  catchError,
  takeUntil,
  debounceTime,
  map,
} from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { UntypedFormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ParamMap, ActivatedRoute, Router } from '@angular/router';
import { ClientService } from '../../../core/services/client.service';
import { Client, ClientLastLogin } from '../../../core/types/client';
import { BankAccount } from '../../../core/types/bank-account';
import { FormComponentData } from '../../../core/types/form-component-data';
import { Utils } from '../../../core/utils/utils';
import { ClientStatus } from '../../../core/types/client-status';
import { BrandService } from '../../../core/services/brand.service';
import { Brand } from '../../../core/types/brand';
import { NotificationService } from '../../../core/services/notification.service';
import { ClientDocument } from '../../../core/types/client-document';
import { LoaderService } from '../../../core/services/loader.service';
import { FormManipulationService } from '../../../core/services/form-manipulation.service';
import { throwError as observableThrowError } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorService } from '../../../core/services/error.service';
import { LoyaltyPointsService } from '../../../core/services/loyalty-points.service';
import { LoyaltyPointsFlowComponent } from '../loyalty-points/loyalty-points-flow/loyalty-points-flow.component';
import { LoyaltyPointsFlow } from '../../../core/types/loyalty-points-flow';
import { AddTagComponent } from '../../tags/add-tag/add-tag.component';
import { TagTypeNames } from '../../../core/types/tag';
import { TagService } from '../../../core/services/tag.service';
import { SmsService } from '../../../core/services/sms.service';
import { EmailService } from '../../../core/services/email.service';
import { ContactService } from '../../../core/services/contacts.service';
import { DialogProviderService } from '@app/core/services/dialog-provider.service';
import { EventLogStateService } from '@app/main/main-layout/right-drawer/event-log/event-log-state.service';
import { EventLogHistoryType, EventLogMode, EventLogSubheader } from '@app/main/main-layout/right-drawer/shared/event-log.enums';
import { FilterGroupType } from '@app/main/filter/types';
@Component({
  selector: 'itfg-client-edit',
  templateUrl: './client-edit.component.html',
  styleUrls: ['./client-edit.component.scss'],
})
export class ClientEditComponent implements OnChanges, OnInit, OnDestroy {
  @Input() clientData: Client;
  @Input() loyaltyPointsAvailableNumberInput: number;
  @Input() public tabSelection: Observable<MatTabChangeEvent>;
  @Output() outputClientData = new EventEmitter<Client>();
  _unsubscribe = new Subject<void>();

  @ViewChild('profileElement') profileEl: ElementRef;
  @ViewChild('profileComponent') profileComponentEl;
  @ViewChild('personalIdElement') personalIdEl: ElementRef;
  @ViewChild('personalIdComponent') personalIdComponentEl;
  @ViewChild('personalElement') personalEl: ElementRef;
  @ViewChild('personalComponent') personalComponentEl;
  @ViewChild('occupationStatusElement')
  occupationStatusEl: ElementRef;
  @ViewChild('occupationStatusComponent')
  occupationStatusComponentEl;

  isNewItem: boolean;
  client: Client;
  isFormValid: any = {};
  isFormValidAfterSave: boolean;
  clientStatuses: ClientStatus[];
  brandData: Brand[];
  actualClient: { email: string; mobile: string };

  public readonly TAGS_LIMIT = 15;
  tagTypeNames: typeof TagTypeNames = TagTypeNames;

  bankAccounts: any;
  initialBankAccounts: BankAccount[];
  clientDocuments: any;
  erroredBankAccounts: any;
  deletedBankAccountIndex: number;
  bankAcountsFormData: any[];
  invalidProfileData: boolean;
  clientComponentData: {
    status: any;
    personalData: any;
    profileData: any;
    idCardDataAndAddress: any;
    occupationStatus: any;
    bankAccounts: any;
    documents: any;
  };

  clientDataChangesDetected: boolean;
  enableSaveButton: boolean;
  tabSelected: boolean;
  isClientInput: boolean;
  lastLogin: ClientLastLogin;
  loyaltyPointsAvailableNumber: number;
  loyaltyPointsFlow: LoyaltyPointsFlow;
  applicationId: string;
  isInClientEdit: boolean = false;

  constructor(
    protected clientService: ClientService,
    protected contactService: ContactService,
    protected tagService: TagService,
    protected brandDataService: BrandService,
    protected translateService: TranslateService,
    protected formBuilder: UntypedFormBuilder,
    protected notificationService: NotificationService,
    protected route: ActivatedRoute,
    protected router: Router,
    public dialog: MatDialog,
    public loaderService: LoaderService,
    public dialogService: DialogProviderService,
    public viewContainerRef: ViewContainerRef,
    protected formManipulationService: FormManipulationService,
    protected errorService: ErrorService,
    protected loyaltyPointsService: LoyaltyPointsService,
    protected smsService: SmsService,
    protected emailService: EmailService,
    protected eventLogStateService: EventLogStateService,
  ) {
    this.isNewItem = true;
    this.client = new Client({});
    this.clientComponentData = this.createClientComponentData();
    this.clientStatuses = [];
    this.bankAcountsFormData = [];
    this.bankAccounts = {};
    this.clientDocuments = {};
    this.erroredBankAccounts = [];
    this.initialBankAccounts = [];
    this.invalidProfileData = false;
    this.clientDataChangesDetected = false;
    this.enableSaveButton = false;
    this.isInClientEdit = this.router.url.includes('clients');
    this.sendPasswordBySms = this.sendPasswordBySms.bind(this);
    this.sendPasswordByEmail = this.sendPasswordByEmail.bind(this);
  }

  ngOnInit() {
    this.clientService.onClientDataChange
      .pipe(takeUntil(this._unsubscribe))
      .subscribe(client => {
        this.client = client;
        this.clientComponentData.profileData = this.profileDataParse(this.client);
        this.clientComponentData.personalData = this.personalDataParse(
          this.client
        );
        this.clientComponentData.idCardDataAndAddress = this.idCardDataAndAddressParse(
          this.client
        );
        this.clientComponentData.occupationStatus = this.occupationStatusDataParse(
          this.client
        );
      });
    this.clientService.onClientOcupationDataChange
      .pipe(takeUntil(this._unsubscribe))
      .subscribe(client => {
        this.client = client;
        this.clientComponentData.occupationStatus = this.occupationStatusDataParse(
          this.client
        );
      });

    this.route.paramMap
      .pipe(
        filter((params: ParamMap) => typeof params.get('id') === 'string'),
        mergeMap((params: ParamMap) => {
          return observableForkJoin(
            this.clientService.getClientById(params.get('id')),
            this.clientService.getClientBankAccounts(params.get('id')),
            this.clientService.getClientDocumentList(params.get('id')),
            this.clientService.getClientLastLogin$(params.get('id')),
            this.loyaltyPointsService.getLoyaltyAvailableNumber$(
              params.get('id')
            )
          );
        }),
        filter(([client]) => client !== null),
        takeUntil(this._unsubscribe)
      )
      .subscribe(
        ([client, bankAccounts, clientDocuments, lastLogin, loyaltyPoints]) => {
          this.lastLogin = lastLogin;
          this.loyaltyPointsAvailableNumber = loyaltyPoints;
          this.setClientDataById(client, bankAccounts, clientDocuments);
        }
      );

    this.route.queryParamMap
    this.route.queryParamMap
      .pipe(
        takeUntil(this._unsubscribe),
        filter(
          (paramMap: ParamMap) =>
            typeof paramMap.get('applicationRef') === 'string'
        ),
        map((paramMap: ParamMap) => paramMap.get('applicationRef'))
      )
      .subscribe((applicationRefId: string) => {
        this.applicationId = applicationRefId;
      });

    this.clientService.clientBankAccounts$
      .pipe(takeUntil(this._unsubscribe))
      .subscribe((bankAccountList: BankAccount[]) => {
        observableForkJoin(
          of(bankAccountList),
          this.clientService.getClientDocumentList(this.clientData.id)
        )
          .pipe(takeUntil(this._unsubscribe))
          .subscribe(([bankAccounts, clientDocuments]) => {
            this.setClientDataById(
              this.clientData,
              bankAccounts,
              clientDocuments
            );
          });
      });

    this.contactService.saveableContact
      .pipe(takeUntil(this._unsubscribe))
      .subscribe((saveBtnEnabled: boolean) => {
        if (saveBtnEnabled) {
          this.clientDataChangesDetected = true;
        } else if (!saveBtnEnabled && this.formChangesOtherThanContactForm()) {
          this.clientDataChangesDetected = true;
        } else {
          this.clientDataChangesDetected = false;
        }
      });

    this.getClientStatuses();
    this.getBrandList();

    this.loyaltyPointsService.loyaltyPointsFlowSubject
      .pipe(
        debounceTime(1000), // TODO: ask Stoil credits returns responce before deleting points
        mergeMap(_ => this.loyaltyPointsService.getLoyaltyAvailableNumber$(this.client.id)),
        takeUntil(this._unsubscribe)
      )
      .subscribe(loyaltyPointsNumber => {
        this.loyaltyPointsAvailableNumber = loyaltyPointsNumber
      })
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.loyaltyPointsAvailableNumberInput) {
      this.loyaltyPointsAvailableNumber = this.loyaltyPointsAvailableNumberInput;
    }

    if (changes.clientData && this.clientData && this.clientData.id) {
      this.isClientInput = true;
      this.setLastLogin(this.clientData.id);
      this.actualClient = {
        email: this.client.email,
        mobile: this.client.email,
      };
    }

    if (changes.tabSelection && this.tabSelection) {
      this.tabSelection
        .pipe(
          filter((tabChanged: MatTabChangeEvent) => tabChanged.index === 1),
          takeUntil(this._unsubscribe)
        )
        .subscribe((tabChanged: MatTabChangeEvent) => {
          setTimeout(() => {
            this.tabSelected = true;
          }, 0);
        });
    }
  }

  setClientDataById(client, bankAccounts, clientDocuments?) {
    this.isNewItem = false;
    this.client = client;
    this.bankAccounts.data = bankAccounts;
    this.clientDocuments.data = clientDocuments;
    this.clientComponentData = this.breakdownClientForComponents(
      client,
      bankAccounts,
      clientDocuments
    );
    this.clientDataChangesDetected = false;
    this.enableSaveButton = false;

    if (this.isInClientEdit) {
      this.clientLogInit();
    }
  }

  statusDataChanged(status: ClientStatus) {
    this.clientDataChangesDetected = true;
    this.enableSaveButton =
      true && Object.values(this.isFormValid).every(Boolean);
    this.client.status = status;
    this.clientComponentData.status = status;
  }

  getBrandList() {
    this.brandDataService.getBrandList()
      .pipe(takeUntil(this._unsubscribe))
      .subscribe(response => {
        this.brandData = response;
      });
  }

  getClientStatuses() {
    this.clientService.getClientStatusList()
      .pipe(takeUntil(this._unsubscribe))
      .subscribe(statusList => {
        this.clientStatuses = statusList;
      });
  }

  setLastLogin(userId: any) {
    this.clientService.getClientLastLogin$(userId)
      .pipe(takeUntil(this._unsubscribe))
      .subscribe(lastLogin => {
        this.lastLogin = lastLogin;
      });
  }

  // onClientSave() {
  //   const bankAccounts$ = this.bankAccounts.map((bankAccount: BankAccount, index: number, array: BankAccount[]): Observable<any> => {
  //     return this.clients.saveClientBankAccount(this.client.id, bankAccount);
  //   });
  //   Observable.forkJoin(
  //     this.clients.saveClient(this.client),
  //     ...bankAccounts$
  //   )
  //     .subscribe(() => {
  //       this.router.navigate(['..'], { relativeTo: this.route });
  //     });
  // }

  createClientComponentData() {
    return {
      status: {
        id: 0,
        name: '',
        reason: '',
      },
      brand: {
        active: null,
        code: '',
        id: null,
        name: '',
      },
      personalData: {
        civilId: '',
        firstName: '',
        middleName: '',
        lastName: '',
        nationality: '',
        secondNationality: '',
        maritalStatus: {},
      },
      profileData: {
        email: '',
        ip: '',
        emailConfirmed: false,
        mobile: '',
        mobileConfirmed: false,
        pep: false,
        loyaltyEnabled: false,
        facebookId: '',
        googleId: ''
      },
      idCardDataAndAddress: {
        idCardNumber: '',
        idCardIssuedDate: '',
        idCardVerified: false,
        idCardAddress: '',
        idCardIssuer: '',
        currentAddress: '',
      },
      occupationStatus: {
        employerId: '',
        employerName: '',
        workPosition: '',
        workAddress: '',
        workExperience: '',
        workExperienceTotal: '',
        employmentType: {},
        education: '',
        bankTransferSalary: '',
        salary: '',
        extraIncomeNotes: '',
        extraIncome: '',
      },
      bankAccounts: {
        bankAccounts: '',
      },
      documents: [],
    };
  }

  breakdownClientForComponents(
    client: Client,
    bankAccounts?: BankAccount[],
    clientDocuments?: ClientDocument[]
  ) {
    return {
      ...this.breakdownClientData(client),
      bankAccounts: this.bankAccountDataParse(bankAccounts),
      documents: clientDocuments,
    };
  }

  breakdownClientData(client: Client) {
    return {
      status: client.status,
      brand: client.brand,
      personalData: this.personalDataParse(client),
      profileData: this.profileDataParse(client),
      idCardDataAndAddress: this.idCardDataAndAddressParse(client),
      occupationStatus: this.occupationStatusDataParse(client),
    };
  }

  personalDataParse(client: Client): any {
    return {
      civilId: client.civilId,
      firstName: client.firstName,
      middleName: client.middleName,
      lastName: client.lastName,
      nationality: client.nationality,
      secondNationality: client.secondNationality,
      maritalStatus: client.maritalStatus,
    };
  }

  personalDataChanged(personalData: FormComponentData) {
    this.client.civilId = personalData.data.civilId;
    this.client.firstName = personalData.data.firstName;
    this.client.middleName = personalData.data.middleName;
    this.client.lastName = personalData.data.lastName;
    this.client.nationality = personalData.data.nationality;
    this.client.secondNationality = personalData.data.secondNationality;
    this.client.maritalStatus = personalData.data.maritalStatus;

    this.isFormValid.personalData = personalData.valid;
    this.clientDataChangesDetected = personalData.dirty;
    this.enableSaveButton = Object.values(this.isFormValid).every(Boolean);
  }

  profileDataParse(client: Client): any {
    return {
      email: client.email,
      brand: client.brand,
      ip: client.ip,
      emailConfirmed: client.emailConfirmed,
      mobile: client.mobile,
      mobileConfirmed: client.mobileConfirmed,
      pep: client.pep,
      loyaltyEnabled: client.loyaltyEnabled,
      facebookId: client.facebookId,
      googleId: client.googleId
    };
  }

  profileDataChanged(profileData: FormComponentData) {
    this.client.email = profileData.data.email;
    this.client.emailConfirmed = profileData.data.emailConfirmed;
    this.client.mobile = profileData.data.mobile;
    this.client.mobileConfirmed = profileData.data.mobileConfirmed;
    this.client.pep = profileData.data.pep;
    this.client.loyaltyEnabled = profileData.data.loyaltyEnabled;
    this.client.brand = profileData.data.brand;
    this.client.facebookId = profileData.data.facebookId;
    this.client.googleId = profileData.data.googleId;

    this.isFormValid.profileData = profileData.valid;
    this.clientDataChangesDetected = profileData.dirty;
    this.enableSaveButton = Object.values(this.isFormValid).every(Boolean);
  }

  idCardDataAndAddressParse(client: Client): any {
    return {
      idCardNumber: client.idCardNumber,
      idCardIssuedDate: client.idCardIssuedDate,
      idCardVerified: client.idCardVerified,
      idCardAddress: client.idCardAddress,
      idCardIssuer: client.idCardIssuer,
      currentAddress: client.currentAddress,
    };
  }

  idCardDataAndAddressChanged(idCardDataAndAddress: FormComponentData) {
    this.client.idCardNumber = idCardDataAndAddress.data.idCardNumber;
    this.client.idCardIssuer = idCardDataAndAddress.data.idCardIssuer;
    this.client.idCardVerified = idCardDataAndAddress.data.idCardVerified;
    this.client.idCardIssuedDate = idCardDataAndAddress.data.idCardIssuedDate;
    this.client.idCardAddress = idCardDataAndAddress.data.idCardAddress;
    this.client.currentAddress = idCardDataAndAddress.data.currentAddress;

    this.isFormValid.idCardDataAndAddress = idCardDataAndAddress.valid;
    this.clientDataChangesDetected = idCardDataAndAddress.dirty;
    this.enableSaveButton = Object.values(this.isFormValid).every(Boolean);
  }

  occupationStatusDataParse(client: Client): any {
    return {
      employerId: client.employerId,
      employerName: client.employerName,
      workPosition: client.workPosition,
      workAddress: client.workAddress,
      workExperience: client.workExperience,
      workExperienceTotal: client.workExperienceTotal,
      employmentType: client.employmentType,
      education: client.education,
      bankTransferSalary: client.bankTransferSalary,
      salary: client.salary,
      extraIncomeNotes: client.extraIncomeNotes,
      extraIncome: client.extraIncome,
    };
  }

  occupationStatusDataChanged(occupationStatusData: FormComponentData) {
    this.client.workAddress = this.client.workAddress
      ? this.client.workAddress
      : {};
    if (this.client.id) {
      if (occupationStatusData.data.workAddress.city) {
        this.client.workAddress.city =
          occupationStatusData.data.workAddress.city;
        if (occupationStatusData.data.workAddress.address) {
          this.client.workAddress.address =
            occupationStatusData.data.workAddress.address;
        } else {
          this.client.workAddress.address = '';
        }
      } else {
        this.client.workAddress = null;
      }
    }

    this.client.employerId = occupationStatusData.data.employerId;
    this.client.employerName = occupationStatusData.data.employerName;
    this.client.workPosition = occupationStatusData.data.workPosition;
    this.client.workExperience = occupationStatusData.data.workExperience;
    this.client.workExperienceTotal =
      occupationStatusData.data.workExperienceTotal;
    this.client.employmentType = occupationStatusData.data.employmentType;
    this.client.education = occupationStatusData.data.education;
    this.client.bankTransferSalary =
      occupationStatusData.data.bankTransferSalary;
    this.client.salary = occupationStatusData.data.salary;
    this.client.extraIncomeNotes = occupationStatusData.data.extraIncomeNotes;
    this.client.extraIncome = occupationStatusData.data.extraIncome;

    this.isFormValid.occupationStatusData = occupationStatusData.valid;
    this.clientDataChangesDetected = occupationStatusData.dirty;
    this.enableSaveButton = Object.values(this.isFormValid).every(Boolean);
  }

  bankAccountDataParse(bankAccounts: BankAccount[]): any {
    return {
      bankAccounts: bankAccounts,
    };
  }

  bankAccountsDataChanged(bankAccountsData: any): any {
    this.bankAccounts.data = bankAccountsData.data;
    this.clientDataChangesDetected = bankAccountsData.dirty;
    this.enableSaveButton = Object.values(this.isFormValid).every(Boolean);
  }

  documentDataChanged(documentsData: any): any {
    this.clientDocuments = documentsData;
    this.clientDataChangesDetected = documentsData.dirty;
  }

  extractIbanFromRequest(error) {
    const ibanKeyName = 'iban=';

    if (!error.url) {
      return;
    }
    const ibanPosition = error.url.indexOf(ibanKeyName);
    const nextParam = error.url.indexOf('&', ibanPosition);
    let ibanValue = '';

    if (ibanPosition && nextParam) {
      ibanValue = error.url.substring(
        ibanPosition + ibanKeyName.length,
        nextParam
      );
    }

    return {
      iban: ibanValue,
    };
  }

  saveBankAccounts(currentBankAccounts, id) {
    const bankAccountRequests: Observable<BankAccount>[] = [];
    let indexOfBankAccount = -1;

    for (let i = 0; i < currentBankAccounts.length; i++) {
      indexOfBankAccount++;
      // check for edit action
      if (currentBankAccounts[i].id) {
        indexOfBankAccount--;
        continue;
      }

      this.bankAcountsFormData.push(new FormData());
      this.bankAcountsFormData[indexOfBankAccount].append(
        'file',
        currentBankAccounts[i]['userDocument']
      );
      const bankAccount$ = this.clientService
        .saveClientBankAccount(
          id,
          currentBankAccounts[i],
          this.bankAcountsFormData[indexOfBankAccount]
        )
        .pipe(share());
      bankAccountRequests.push(bankAccount$);
      bankAccount$
        .pipe(takeUntil(this._unsubscribe))
        .subscribe(
          val => {
          },
          err => {
            this.erroredBankAccounts.push(this.extractIbanFromRequest(err));
          }
        );
    }
    // reset the current form data
    this.bankAcountsFormData = [];
    indexOfBankAccount = -1;

    if (bankAccountRequests.length > 0) {
      return observableForkJoin(
        ...bankAccountRequests
        // );
      ).pipe(share());
    } else {
      return observableOf([]);
    }
  }

  editBankAccount(bankAccount: any) {
    if (!bankAccount) {
      return;
    }

    if (bankAccount.hasOwnProperty('userDocument')) {
      this.bankAcountsFormData = [];
      this.bankAcountsFormData.push(new FormData());
      this.bankAcountsFormData[0].append('file', bankAccount['userDocument']);
    }

    this.clientService
      .updateClientBankAccount(
        this.client.id,
        bankAccount.id,
        bankAccount,
        this.bankAcountsFormData[0]
      )
      .pipe(takeUntil(this._unsubscribe))
      .subscribe(
        res => {
          if (res.userDocument) {
            this.clientComponentData.documents.push(res.userDocument);
            this.clientService.onDataChange.next(
              this.clientComponentData.documents
            );
            this.client = { ...this.client };
            this.client = { ...this.client };
          }

          this.bankAcountsFormData = [];
          this.translateService
            .get(['clients.editedAccount'], { iban: bankAccount.iban })
            .pipe(takeUntil(this._unsubscribe))
            .subscribe(translation => {
              this.notificationService.showLocalizedSuccessMessage({
                notificationText: translation['clients.editedAccount'],
              });
            });
          this.clientService.getClientBankAccounts(this.client.id)
            .pipe(takeUntil(this._unsubscribe))
            .subscribe(
              val => {
                this.clientComponentData.bankAccounts = { bankAccounts: val };
                this.outputClientData.emit(this.client);
              },
              err => {
              }
            );
        },
        err => {
          this.bankAcountsFormData = [];
          this.erroredBankAccounts.push(this.extractIbanFromRequest(err));
        }
      );
  }

  deleteBankAccount(bankAccount: any) {
    this.clientService
      .deleteClientBankAccount(this.client.id, bankAccount.bankAccount.id.value)
      .pipe(takeUntil(this._unsubscribe))
      .subscribe(
        res => {
          this.deletedBankAccountIndex = bankAccount.index;
          this.translateService
            .get(['clients.deletedAccount'], {
              iban: bankAccount.bankAccount.iban.value,
            })
            .pipe(takeUntil(this._unsubscribe))
            .subscribe(translation => {
              this.notificationService.showLocalizedSuccessMessage({
                notificationText: translation['clients.deletedAccount'],
              });
            });

          this.clientService.getClientBankAccounts(this.client.id)
            .pipe(takeUntil(this._unsubscribe))
            .subscribe(
              val => {
                this.clientComponentData.bankAccounts = { bankAccounts: val };
                this.bankAccounts.data = val;
              },
              err => {
                console.log(err);
              }
            );
        },
        err => {
          console.log(err);
        }
      );
  }

  deleteBankAccountDocument(bankAccount: any) {
    this.clientService
      .deleteClientDocument(this.client.id, bankAccount.userDocument.id)
      .pipe(takeUntil(this._unsubscribe))
      .subscribe(
        res => {
          this.translateService
            .get(['clients.deletedDocument'], { iban: bankAccount.iban })
            .pipe(takeUntil(this._unsubscribe))
            .subscribe(translation => {
              this.notificationService.showLocalizedSuccessMessage({
                notificationText: translation['clients.deletedDocument'],
              });
            });

          this.clientService.getClientBankAccounts(this.client.id)
            .pipe(takeUntil(this._unsubscribe))
            .subscribe(
              val => {
                this.clientComponentData.bankAccounts.bankAccounts = val;
                this.clientService.onBankAccoutDataChange.next(
                  this.clientComponentData.bankAccounts
                );
              },
              err => {
                console.log(err);
              }
            );

          for (let i = 0; i < this.clientComponentData.documents.length; i++) {
            const document = this.clientComponentData.documents[i];

            if (bankAccount.userDocument.id === document.id) {
              this.clientComponentData.documents.splice(i, 1);
              this.clientService.onDataChange.next(
                this.clientComponentData.documents
              );
            }
          }
        },
        err => {
          console.log(err);
        }
      );
  }

  saveClientDocuments(currentClientDocuments, id) {
    const clientDocumentRequests: Observable<ClientDocument>[] = [];

    for (let i = 0; i < currentClientDocuments.length; i++) {
      if (
        currentClientDocuments[i].document.size ||
        currentClientDocuments[i].document.type
      ) {
        let clientFile$;
        if (currentClientDocuments[i].id) {
          // Update file
        } else {
          // Save file
        }

        const formData: FormData[] = [];
        formData.push(new FormData());
        formData[0].append('file', currentClientDocuments[i]['document']);

        clientFile$ = this.clientService
          .addClientDocument(id, formData[0])
          .pipe(share());
        clientDocumentRequests.push(clientFile$);
        clientFile$
          .pipe(catchError(this.catchDocumentError.bind(this)))
          .subscribe(val => {
          });
      }
    }

    if (clientDocumentRequests.length > 0) {
      return observableForkJoin(...clientDocumentRequests).pipe(share());
    } else {
      return observableOf([]);
    }
  }

  catchDocumentError(error: HttpErrorResponse) {
    this.notificationService.showLocalizedErrorMessage({
      notificationText: 'clients.unableToSaveDoc',
    });
    this.loaderService.display(false);
    return observableThrowError(error);
  }

  deleteClientDocument(clientDocumet: ClientDocument) {
    for (
      let i = 0;
      i < this.clientComponentData.bankAccounts.bankAccounts.length;
      i++
    ) {
      const bankAccountDocument = this.clientComponentData.bankAccounts
        .bankAccounts[i].userDocument;

      if (bankAccountDocument && clientDocumet.id === bankAccountDocument.id) {
        this.clientComponentData.bankAccounts.bankAccounts[
          i
        ].userDocument = null;
        this.clientService.onBankAccoutDataChange.next(
          this.clientComponentData.bankAccounts
        );
      }
    }
    this.clientService
      .deleteClientDocument(this.client.id, clientDocumet.id)
      .pipe(takeUntil(this._unsubscribe))
      .subscribe(response => {
        for (let i = 0; i < this.clientComponentData.documents.length; i++) {
          const document = this.clientComponentData.documents[i];

          if (clientDocumet.id === document.id) {
            this.clientComponentData.documents.splice(i, 1);
            this.clientService.onDataChange.next(
              this.clientComponentData.documents
            );
          }
        }
      });
  }

  save() {
    this.enableSaveButton = false;
    // Check if all fields are valid and mark the invalid ones
    this.checkIfFormIsValid(this.isFormValid);
    const idCardVerifiedOriginal = this.client.idCardVerified;
    if (!this.isFormValidAfterSave) {
      return;
    }

    this.client = Utils.checkObjectForEmptyValuesRecursive(this.client);

    if (idCardVerifiedOriginal === null) {
      this.client.idCardVerified = null;
    }

    if (!this.client.status) {
      this.notificationService.showLocalizedSnackbarNotification({
        notificationText: 'clients.selectClientStatusMessage',
      });
      return;
    }

    this.contactService.saveAllContacts();

    return this.clientService
      .saveClient(this.client)
      .toPromise()
      .then(
        (savedClient: Client) => {
          this.client = savedClient;
          this.isNewItem = false;

          this.saveClientBankAccountAndDocuments(savedClient)
            .pipe(takeUntil(this._unsubscribe))
            .subscribe(
              response => {
                for (const entry of response) {
                  const isArray = Array.isArray(entry);

                  if (
                    isArray &&
                    typeof entry !== 'undefined' &&
                    entry.length > 0
                  ) {
                    for (const element of entry) {
                      if ('iban' in element) {
                        this.clientComponentData.bankAccounts.bankAccounts.push(
                          element
                        );
                        this.bankAccounts.data = this.clientComponentData.bankAccounts;
                        this.clientComponentData.bankAccounts = [
                          ...this.clientComponentData.bankAccounts.bankAccounts,
                        ];
                        if (element.userDocument !== null) {
                          this.clientComponentData.documents.push(
                            element.userDocument
                          );
                        }
                      }

                      if ('name' in element) {
                        this.clientComponentData.documents.push(element);
                      }
                    }
                  } else if (!isArray && entry !== 'undefined') {
                    if ('iban' in entry) {
                      this.clientComponentData.bankAccounts.bankAccounts.push(
                        entry
                      );
                      this.bankAccounts.data = this.clientComponentData.bankAccounts;
                      this.clientComponentData.bankAccounts = [
                        ...this.clientComponentData.bankAccounts.bankAccounts,
                      ];
                      if (entry.userDocument !== null) {
                        this.clientComponentData.documents.push(
                          entry.userDocument
                        );
                        this.clientComponentData.documents = [
                          ...this.clientComponentData.documents,
                        ];
                      }
                    }

                    if ('name' in entry) {
                      this.clientComponentData.documents.push(entry);
                    }
                  }
                }
                this.clientService.onDataChange.next(
                  this.clientComponentData.documents
                );
                this.clientService.onBankAccoutDataChange.next(
                  this.clientComponentData.bankAccounts
                );
                this.loaderService.display(false);
                this.clientComponentData = this.breakdownClientForComponents(
                  savedClient,
                  this.clientComponentData.bankAccounts,
                  this.clientComponentData.documents
                );
              }
            );

          this.outputClientData.emit(savedClient);
          this.clientService.onClientSaved.next(savedClient);

          this.profileComponentEl.profileDataForm.markAsPristine();
          this.personalIdComponentEl.profileDataForm.markAsPristine();
          this.personalComponentEl.personalDataForm.markAsPristine();
          this.occupationStatusComponentEl.occupationStatusForm.markAsPristine();

          this.notificationService.showLocalizedSuccessMessage({
            notificationText: 'clients.successfullySavedClient',
          });

        },
        (error: HttpErrorResponse) => {
          this.errorService.showSnackbarErrorMessage(error);
          return Promise.reject(error);
        }
      );
  }

  saveClientBankAccountAndDocuments(savedClient: Client): Observable<any> {
    return new Observable(observer => {
      const currentBankAccounts = this.bankAccounts.data;
      const currentClientDocuments = this.clientDocuments.data;
      if (
        currentBankAccounts &&
        currentBankAccounts.length &&
        currentClientDocuments &&
        currentClientDocuments.length
      ) {
        this.loaderService.display(true);
        const callBackObservables = [];

        callBackObservables.push(
          this.saveBankAccounts(currentBankAccounts, savedClient.id).pipe(
            share()
          )
        );
        callBackObservables.push(
          this.saveClientDocuments(currentClientDocuments, savedClient.id).pipe(
            share()
          )
        );

        observableForkJoin(...callBackObservables)
          .pipe(takeUntil(this._unsubscribe))
          .subscribe(
            response => {
              if (!this.clientData || !this.clientData.id) {
                this.navigateToNextPage();
              }

              observer.next(response);
              observer.complete();
            },
            err => {
              console.error(err);
              this.loaderService.display(false);
            }
          );
      } else if (currentBankAccounts && currentBankAccounts.length) {
        this.loaderService.display(true);

        this.saveBankAccounts(currentBankAccounts, savedClient.id)
          .pipe(takeUntil(this._unsubscribe))
          .subscribe(
            result => {
              if (!this.clientData || !this.clientData.id) {
                this.navigateToNextPage();
              }

              observer.next(result);
              observer.complete();
            }
          );
      } else if (currentClientDocuments && currentClientDocuments.length) {
        this.loaderService.display(true);

        this.saveClientDocuments(
          currentClientDocuments,
          savedClient.id
        )
          .pipe(takeUntil(this._unsubscribe))
          .subscribe(result => {
            if (!this.clientData || !this.clientData.id) {
              this.navigateToNextPage();
            }

            observer.next(result);
            observer.complete();
          });
      } else {
        if (!this.clientData || !this.clientData.id) {
          this.navigateToNextPage();
        }
        observer.next([]);
        observer.complete();
      }
    });
  }

  sendPasswordByEmail(event) {
    this.dialogService
      .openConfirm({
        message: this.translateService.instant(
          'clients.sendPasswordByEmailConfirmationMessage',
          { email: this.client.email }
        ),
        viewContainerRef: this.viewContainerRef,
        title: this.translateService.instant('global.confirm'),
        cancelButton: this.translateService.instant('global.cancel'),
        acceptButton: this.translateService.instant('global.confirm'),
      })
      .afterClosed()
      .pipe(filter(accept => accept === true))
      .subscribe((accept: boolean) => {
        if (accept) {
          this.emailService.sendTemporaryPassword(this.client.id).subscribe(email => {
            this.notificationService.showLocalizedSuccessMessage({
              notificationText: 'communication.emailSuccessfullySent',
            });
            this.emailService.onEmailSend.next(email);
          });
        }
      });
  }

  sendPasswordBySms(event) {
    this.dialogService
      .openConfirm({
        message: this.translateService.instant(
          'clients.sendPasswordBySmsConfirmationMessage',
          { phoneNumber: this.client.mobile }
        ),
        viewContainerRef: this.viewContainerRef,
        title: this.translateService.instant('global.confirm'),
        cancelButton: this.translateService.instant('global.cancel'),
        acceptButton: this.translateService.instant('global.confirm'),
      })
      .afterClosed()
      .pipe(filter(accept => accept === true))
      .subscribe((accept: boolean) => {
        if (accept) {
          this.smsService.sendTemporaryPassword(this.client.id).subscribe(sms => {
            this.notificationService.showLocalizedSuccessMessage({
              notificationText: 'communication.smsSuccessfullySent',
            });
            this.smsService.onSmsSend.next(sms);
          });
        }
      });
  }

  scrollToTop() {
    // Custom scroll to top on invalid form
    const currentElement = document.getElementsByClassName(
      'main-layout-container'
    )[0];
    const scrollToTop = window.setInterval(function () {
      const pos = currentElement.scrollTop;
      if (pos > 0 && pos > 400) {
        currentElement.scrollTo(0, pos - 60); // how far to scroll on each step
      } else if (pos > 0 && pos > 150) {
        currentElement.scrollTo(0, pos - 20); // how far to scroll on each step
      } else if (pos > 0) {
        currentElement.scrollTo(0, pos - 10); // how far to scroll on each step
      } else {
        window.clearInterval(scrollToTop);
      }
    }, 16); // how fast to scroll (this equals roughly 60 fps)
  }

  checkIfFormIsValid(isValidFormObject) {
    const formProperties = Object.keys(isValidFormObject).reverse();
    this.isFormValidAfterSave = true;

    for (const prop of formProperties) {
      if (!isValidFormObject[prop]) {
        this.invalidProfileData = true;
        this.notificationService.showLocalizedErrorMessage({
          notificationText: 'forms.formSavedIsInvalid',
        });

        switch (prop) {
          case 'profileData':
            this.markInvalidFormAndScroll(
              this.profileComponentEl.profileDataForm,
              this.profileEl.nativeElement
            );
            break;
          case 'personalData':
            this.markInvalidFormAndScroll(
              this.personalComponentEl.personalDataForm,
              this.personalEl.nativeElement
            );
            break;
          case 'idCardDataAndAddress':
            this.markInvalidFormAndScroll(
              this.personalIdComponentEl.profileDataForm,
              this.personalIdEl.nativeElement
            );
            break;
          case 'occupationStatusData':
            this.markInvalidFormAndScroll(
              this.occupationStatusComponentEl.occupationStatusForm,
              this.occupationStatusEl.nativeElement
            );
            break;
        }

        this.isFormValidAfterSave = false;
      }
    }
  }

  openLoyaltyPointsDetails() {
    this.loyaltyPointsService.getLoyaltyPointsFlow$(this.client.id).subscribe(pointsFlow => {
      this.loyaltyPointsFlow = pointsFlow;
      const dialogRef = this.dialog.open(LoyaltyPointsFlowComponent, {
        data: {
          received: this.loyaltyPointsFlow.received,
          used: this.loyaltyPointsFlow.used,
          clientId: this.client.id
        },
        autoFocus: false,
        restoreFocus: false
      });
      dialogRef
        .afterClosed()
        .subscribe(result => {
          if (result) {
            this.loyaltyPointsService.getLoyaltyAvailableNumber$(this.client.id).subscribe(points => {
              this.loyaltyPointsAvailableNumber = points;
            });
          }
        });
    });
  }

  clientLogInit() {
    const client = this.client ? this.client : null;
    const phoneNumber = this.client.mobile;

    this.eventLogStateService.setState({
      mode: EventLogMode.CLIENT,
      subheader: EventLogSubheader.PBX,
      historyType: EventLogHistoryType.PHONE,
      client: client,
      phoneNumber: phoneNumber
    },
      {
        openDrawer: true,
        isInClientEdit: true,
      });
  }

  markInvalidFormAndScroll(dataForm, element) {
    this.formManipulationService.markAllControlsTouched(dataForm);
    Utils.scrollElementToTop(element);
  }

  navigateToNextPage() {
    this.router.navigate(['clients', this.client.id]);
  }
  openAddClientTag() {
    const dialogRef = this.dialog.open(AddTagComponent, {
      data: {
        id: this.client.id,
        tags: this.client.tags,
        type: 'client',
      },
      restoreFocus: false,
      width: '400px',
    });
    dialogRef.afterClosed().subscribe(options => {
      if (options) {
        const tagUsers$ = this.tagService.tagUsers(
          [options.id],
          options.tagId
        );
        tagUsers$.subscribe(res => {
          this.client.tags.push(options.tagValue);
        });
      }


    });
  }

  formChangesOtherThanContactForm(): boolean {
    const profileDataChanged = this.profileComponentEl.profileDataForm.dirty;
    const personalIdDataChanged = this.personalIdComponentEl.profileDataForm
      .dirty;
    const personalDataChanged = this.personalComponentEl.personalDataForm.dirty;
    const occupationStatusChanged = this.occupationStatusComponentEl
      .occupationStatusForm.dirty;

    return (
      profileDataChanged ||
      personalIdDataChanged ||
      personalDataChanged ||
      occupationStatusChanged
    );
  }

  ngOnDestroy() {
    this.eventLogStateService.exitEditComponent(FilterGroupType.CLIENT);
    this._unsubscribe.next();
    this._unsubscribe.complete();
  }
}
