<mat-card appearance="outlined">
  <form novalidate
        [itfgPermissions]="['USER_WRITE']"
        [formGroup]="personalDataForm">
    <mat-card-title>
      <span class="edit-screen-card-title"
            [translate]="'clients.personalData'">Personal data</span>
    </mat-card-title>
    <mat-card-content class="remove-mat-form-field-wrapper">
      <div layout="column">
        <div flex
             layout="row"
             layout-wrap
             layout-align="space-between center">
          <mat-form-field flex>
            <input matInput
                   itfgTrimInput
                   id="civilId"
                   [readonly]="civilIdReadonly"
                   type="text"
                   formControlName="civilId"
                   #civilId
                   placeholder="{{ 'global.personalId' | translate }}" />
            <mat-label>{{ 'global.personalId' | translate }}</mat-label>
            <div matSuffix
                 layout="row"
                 layout-align="center center">
              <span>{{(civilIdGender && civilId.value) ? ((('global.' + civilIdGender + 'Short') | translate) + ' / ') :
                ''}}{{(civilIdAge && civilId.value) ? (civilIdAge + ' ' + (('global.ageShort') | translate)):
                ''}}</span>
              <mat-error *ngIf="
                  personalDataForm.get('civilId').hasError('onlyNumbers') ||
                  personalDataForm.get('civilId').hasError('minlength') ||
                  personalDataForm.get('civilId').hasError('maxlength') ||
                  personalDataForm.get('civilId').hasError('incorrectEgnFormat') ||
                  (personalDataForm.get('civilId').touched && personalDataForm.get('civilId').hasError('required'))"
                         [translate]="'clients.invalidCivilId'">Invalid civil id
              </mat-error>
              <button flex
                      type="button"
                      *ngIf="!civilIdIsInBlacklist"
                      mat-icon-button
                      matTooltip="{{ 'clients.addCivilIdToBlacklist' | translate }}"
                      [matTooltipDisabled]="isNewItem"
                      matTooltipPosition="above"
                      [disabled]="isNewItem || (['BLACKLIST_READ'] | isUnauthorized | async)"
                      class="input-suffix-button add-toblacklist-flag-color"
                      (click)="addToBlacklist()">
                <mat-icon>not_interested
                </mat-icon>
              </button>
              <button flex
                      type="button"
                      *ngIf="civilIdIsInBlacklist"
                      mat-icon-button
                      [disabled]="isNewItem || (['BLACKLIST_READ'] | isUnauthorized | async)"
                      (click)="editInBlacklist()"
                      class="input-suffix-button blacklisted-flag-color">
                <mat-icon matTooltip="{{ 'clients.editCivilIdInBlacklist' | translate }}"
                          [matTooltipDisabled]="isNewItem"
                          matTooltipPosition="above">not_interested
                </mat-icon>
              </button>
            </div>
          </mat-form-field>
        </div>
        <mat-form-field>
          <input matInput
                 itfgTrimInput
                 type="text"
                 formControlName="firstName"
                 placeholder="{{ 'users.firstName' | translate }}" />
          <mat-label>{{ 'users.firstName' | translate }}</mat-label>
          <mat-error *ngIf="personalDataForm.get('firstName').hasError('maxlength')"
                     [translate]="'forms.maxLength'"
                     [translateParams]="{value: namesValidation.maxLength}">Max value error
          </mat-error>
          <mat-error *ngIf="personalDataForm.get('firstName').hasError('lacksLatin')" [translate]="'forms.lacksLatin'">
            Latin characters are not allowed
          </mat-error>
        </mat-form-field>
        <mat-form-field>
          <input matInput
                 itfgTrimInput
                 type="text"
                 formControlName="middleName"
                 placeholder="{{ 'users.middleName' | translate }}"
                 flex="50" />
          <mat-label>{{ 'users.middleName' | translate }}</mat-label>
          <mat-error *ngIf="personalDataForm.get('middleName').hasError('maxlength')"
                     [translate]="'forms.maxLength'"
                     [translateParams]="{value: namesValidation.maxLength}">Max value error
          </mat-error>
          <mat-error *ngIf="personalDataForm.get('middleName').hasError('lacksLatin')" [translate]="'forms.lacksLatin'">
            Latin characters are not allowed
          </mat-error>
        </mat-form-field>
        <mat-form-field>
          <input matInput
                 itfgTrimInput
                 type="text"
                 formControlName="lastName"
                 placeholder="{{ 'users.lastName' | translate }}" />
          <mat-label>{{ 'users.lastName' | translate }}</mat-label>
          <mat-error *ngIf="personalDataForm.get('lastName').hasError('maxlength')"
                     [translate]="'forms.maxLength'"
                     [translateParams]="{value: namesValidation.maxLength}">Max value error
          </mat-error>
          <mat-error *ngIf="personalDataForm.get('lastName').hasError('lacksLatin')" [translate]="'forms.lacksLatin'">
            Latin characters are not allowed
          </mat-error>
        </mat-form-field>
        <div flex="100"
             layout="row"
             layout-wrap
             layout-align="space-between center">
          <mat-form-field flex>
            <input matInput
                   type="text"
                   formControlName="nationality"
                   [matAutocomplete]="nationality"
                   itfgSelectFirstAutocompleteOptionOnBlur
                   [value]=""
                   placeholder="{{ 'global.citizenship' | translate }}" />
            <mat-label>{{ 'global.citizenship' | translate }}</mat-label>
            <mat-error *ngIf="personalDataForm.get('nationality')?.hasError('lacksLatin')"
                       [translate]="'forms.fieldShouldNotHaveLatinCharacters'"></mat-error>
            <mat-error *ngIf="personalDataForm.get('nationality')?.hasError('notValidCountry') && !(personalDataForm.get('nationality')?.hasError('lacksLatin'))"
                       [translate]="'forms.notValidCountry'"></mat-error>
            <mat-autocomplete #nationality="matAutocomplete"
                              itfgHighlightFirstAutocompleteOptionOnChange
                              [displayWith]="mapCountryToDisplayValue">
              <mat-option *ngFor="let nationality of filteredCountries | async"
                          [value]="nationality">
                {{ nationality.name }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
          <mat-form-field flex>
            <input matInput
                   type="text"
                   formControlName="secondNationality"
                   [matAutocomplete]="secondNationality"
                   itfgSelectFirstAutocompleteOptionOnBlur
                   [value]=""
                   placeholder="{{ 'global.secondCitizenship' | translate }}" />
            <mat-label>{{ 'global.secondCitizenship' | translate }}</mat-label>
            <mat-error *ngIf="personalDataForm.get('secondNationality')?.hasError('lacksLatin')"
                       [translate]="'forms.fieldShouldNotHaveLatinCharacters'"></mat-error>
            <mat-error *ngIf="personalDataForm.get('secondNationality')?.hasError('notValidCountry') && !(personalDataForm.get('nationality')?.hasError('lacksLatin'))"
                       [translate]="'forms.notValidCountry'"></mat-error>
            <mat-autocomplete #secondNationality="matAutocomplete"
                              itfgHighlightFirstAutocompleteOptionOnChange
                              [displayWith]="mapCountryToDisplayValue">
              <mat-option *ngFor="let nationality of filteredSecondCountryList | async"
                          [value]="nationality">
                {{ nationality.name }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
        <mat-form-field>
          <mat-select matInput
                      formControlName="maritalStatus"
                      disableOptionCentering="true"
                      [compareWith]="compareItemsById"
                      placeholder="{{ 'identityReports.maritalStatus.name' | translate }}">
            <mat-option [disabled]="(['USER_WRITE'] | isUnauthorized | async)"
                        *ngFor="let status of maritalStatuses"
                        [value]="status">{{ status.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </mat-card-content>
  </form>
</mat-card>
