<div class="event-log">
  <div class="header"
       flex
       layout="row"
       layout-align="space-between center">
    <mat-form-field subscriptSizing="dynamic"
                    flex="1"
                    class="search-input">
      <input type="text"
             [readonly]="!mode"
             autocomplete="off"
             [formControl]="filterLogCtrl"
             placeholder="{{ 'global.search' | translate }}"
             matInput />
      <button [disabled]="!filterLogCtrl.value"
              [disabled]="!mode"
              tabindex="-1"
              matSuffix
              type="button"
              mat-icon-button
              (click)="clearFormControl(filterLogCtrl)">
        <mat-icon>clear</mat-icon>
      </button>
    </mat-form-field>

    <div class="mode-toggle-div dim-icons"
         layout="row"
         layout-align="end center">
      <mat-button-toggle-group [ngModel]="subheader"
                               hideSingleSelectionIndicator>
        <mat-button-toggle [disabled]="!mode"
                           [value]="EventLogSubheader.FILTERS"
                           (click)="toggleSubheader(EventLogSubheader.FILTERS)"
                           matTooltip="{{ 'historyLog.nav.filters' | translate }}">
          <mat-icon [matBadge]="logIssues.length > 0 ? '!' : null"
                    matBadgeColor="warn"
                    matBadgeSize="small">filter_list</mat-icon>
        </mat-button-toggle>

        <mat-button-toggle [disabled]="!call && !credit && !client"
                           [value]="EventLogSubheader.PBX"
                           (click)="toggleSubheader(EventLogSubheader.PBX)"
                           matTooltip="{{ 'historyLog.nav.pbx' | translate }}">
          <mat-icon fontSet="material-symbols-outlined"
                    class="fill">support_agent</mat-icon>
        </mat-button-toggle>

        <mat-button-toggle [value]="EventLogSubheader.NOTES"
                           (click)="toggleSubheader(EventLogSubheader.NOTES)"
                           matTooltip="{{ 'historyLog.nav.notes' | translate }}"
                           [disabled]="!credit">
          <mat-icon fontSet="material-symbols-outlined"
                    class="fill">
            note_stack
          </mat-icon>
        </mat-button-toggle>
      </mat-button-toggle-group>

      <button mat-icon-button
              matTooltip="{{ 'historyLog.nav.refresh' | translate }}"
              [disabled]="isLoading || !mode"
              (click)="fetchEventLog()">
        <mat-icon>refresh</mat-icon>
      </button>
    </div>
  </div>

  <div class="collapsible-subheader"
       [class.expanded]="subheader === EventLogSubheader.FILTERS">
    <mat-card appearance="outlined">
      <itfg-event-log-filtering [logIssues]="logIssues"></itfg-event-log-filtering></mat-card>
  </div>

  <div class="collapsible-subheader"
       [class.expanded]="subheader === EventLogSubheader.PBX">
    <mat-card appearance="outlined">
      <itfg-pbx></itfg-pbx>
    </mat-card>
  </div>

  <div class="collapsible-subheader"
       [class.expanded]="subheader === EventLogSubheader.NOTES">
    <mat-card appearance="outlined">
      <itfg-event-log-notes></itfg-event-log-notes>
    </mat-card>
  </div>

  <div class="table-div">
    <!-- Spinner whenever we are fetching the event log -->
    <div *ngIf="isLoading"
         class="progress-spinner-container">
      <mat-spinner class="progress-spinner"
                   strokeWidth="10"
                   [diameter]="150"></mat-spinner>
    </div>

    <!-- Whenever the log data is empty -->
    <div *ngIf="!isLoading && activeFilteredLogData?.length === 0"
         flex
         layout-align="center center"
         class="text-center">
      <h4 [translate]="
          historyType === historyTypes.CREDIT
            ? ('historyLog.emptyCreditLog' | translate)
            : historyType === historyTypes.PHONE
              ? ('historyLog.emptyCallLog' | translate)
              : ('historyLog.emptyLog' | translate)
        ">
        There is no log data.
      </h4>
    </div>

    <table mat-table
           *ngIf="
        !isLoading &&
        activeFilteredLogData?.length > 0 &&
        administrators?.length > 0
      "
           [dataSource]="activeFilteredLogData"
           multiTemplateDataRows>
      <ng-container matColumnDef="time">
        <td mat-cell
            class="time-column"
            *matCellDef="let element">
          <span innerHTML="{{ element.displayText.time }}"></span>
        </td>
      </ng-container>

      <ng-container matColumnDef="editor">
        <td mat-cell
            *matCellDef="let audit">
          <span class="audit-editor tooltip-select"
                [matTooltip]="audit.editor || client?.email"
                matTooltipPosition="above">
            <span innerHTML="{{ audit.displayText.editor }}"></span>
          </span>
          <span>
            <span class="audit-operation"
                  innerHTML="{{ ' ' + audit.displayText.operation + ' ' }}"></span>
            <span class="audit-type"
                  innerHTML="{{ audit.displayText.type }}"></span>
          </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="creditId">
        <td mat-cell
            *matCellDef="let audit"
            class="creditId-column">
          <div class="flex-align">
            <span matTooltip="{{ 'global.credit' | translate }}"
                  matTooltipPosition="above"
                  class="tooltip-select">
              <a target="_blank"
                 [routerLink]="['/credits', audit.displayText.creditId]"
                 innerHtml="{{ audit.displayText.creditId }}"></a>
            </span>
            <button *ngIf="
                (audit.type === eventLogAuditTypes.NOTE &&
                  (audit.editableEntityData | isNoteEditable)) ||
                (audit.type === eventLogAuditTypes.CALL &&
                  ((audit.editableEntityData.notes | isNoteEditable) ||
                    (currentlyLoggedInOperator
                      | hasCallRecordAccess: audit.editableEntityData))) || 
                      (audit.type === eventLogAuditTypes.CALL && 
         audit.editableEntityData.status !== callStatuses.INITIATED && 
         audit.editableEntityData.status !== callStatuses.ONGOING)"
                    mat-icon-button
                    [matMenuTriggerFor]="optionsMenu"
                    [matMenuTriggerData]="{
                auditData: audit.editableEntityData,
                auditType: audit.type,
              }"
                    class="action-button">
              <mat-icon>more_vert</mat-icon>
            </button>
          </div>
          <mat-menu #optionsMenu="matMenu">
            <ng-template matMenuContent
                         let-auditData="auditData"
                         let-auditType="auditType">
              <button *ngIf="
                  auditType === eventLogAuditTypes.NOTE ||
                  (auditType === eventLogAuditTypes.CALL &&
                    (auditData.notes | isNoteEditable))
                "
                      mat-menu-item
                      (click)="
                  openEditNoteDialog(
                    auditType === eventLogAuditTypes.NOTE
                      ? auditData
                      : auditType === eventLogAuditTypes.CALL
                        ? auditData.notes
                        : []
                  )
                ">
                <mat-icon>edit</mat-icon>
                <span>{{ 'global.edit' | translate }}</span>
              </button>
              <button *ngIf="audit.type === eventLogAuditTypes.CALL && 
         audit.editableEntityData.status !== callStatuses.INITIATED && 
         audit.editableEntityData.status !== callStatuses.ONGOING"
                      mat-menu-item
                      (click)="viewCallDetails(audit.editableEntityData)">
                <mat-icon>info</mat-icon>
                <span>{{ 'communication.viewCallDetails' | translate }}</span>
              </button>
              <button *ngIf="
                  auditType === eventLogAuditTypes.CALL &&
                  (administrators
                    | hasCallRecordAccess
                      : currentlyLoggedInOperator
                      : auditData)
                "
                      mat-menu-item
                      (click)="viewCallRecord(auditData)">
                <mat-icon>record_voice_over</mat-icon>
                <span>{{ 'communication.viewCallRecord' | translate }}</span>
              </button>
            </ng-template>
          </mat-menu>
        </td>
      </ng-container>

      <ng-container matColumnDef="expandedDetail">
        <td mat-cell
            *matCellDef="let audit"
            [attr.colspan]="displayedColumns.length">
          <div class="example-element-detail">
            <div class="example-element-description">
              <span innerHTML="{{ audit.displayText.description }} "></span>
              <!-- <ng-container *ngIf="audit.editableEntityData?.topics && audit.editableEntityData?.topics.length > 0">
                <ng-container *topicsVisualization="audit.editableEntityData?.topics; let topic; let isLast = isLast">
                  <span matTooltip="{{ topic.desc }}"
                        matTooltipPosition="above">{{ topic.name }}</span><span *ngIf="!isLast">, </span>
                </ng-container>
              </ng-container> -->
            </div>
          </div>
        </td>
      </ng-container>

      <tr mat-row
          *matRowDef="let element; columns: displayedColumns"
          class="example-element-row"
          [ngClass]="{'highlighted-row': element.highlight}"
          [class.example-expanded-row]="expandedElement === element"
          (click)="expandedElement = expandedElement === element ? null : element"></tr>
      <tr mat-row
          *matRowDef="let row; columns: ['expandedDetail']"
          class="example-detail-row"></tr>
    </table>
  </div>
</div>
