import {tap} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {HttpInterceptor} from '@angular/common/http';
import {HttpRequest, HttpHandler} from '@angular/common/http';
import {AuthStorageService} from './auth-storage.service';
import {HttpErrorResponse} from '@angular/common/http';
import {Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {ErrorDialogComponent} from '../components/error-dialog/error-dialog.component';
import {ErrorService} from './error.service';
import { InsuranceErrors } from '../types/insurance';

const ERROR_MESSAGE_TYPES = {
  'com.itfgroup.domain.user.MobileAlreadyConfirmed':
    'com.itfgroup.domain.user.MobileAlreadyConfirmed',
  'com.itfgroup.domain.user.EmailAlreadyConfirmed':
    'com.itfgroup.domain.user.EmailAlreadyConfirmed',
  'com.itfgroup.domain.refinance.RefinanceValidationException':
    'com.itfgroup.domain.refinance.RefinanceValidationException',
  'com.itfgroup.domain.user.EmailAlreadyTaken':
    'com.itfgroup.domain.user.EmailAlreadyTaken',
  'com.itfgroup.domain.user.CivilIdAlreadyTaken':
  'com.itfgroup.domain.user.CivilIdAlreadyTaken',
  'com.itfgroup.auth.mfa.InvalidTotpCodeException': 'com.itfgroup.auth.mfa.InvalidTotpCodeException',
};

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  storage: AuthStorageService;
  router: Router;
  dialog: MatDialog;

  constructor(
    storage: AuthStorageService,
    router: Router,
    dialog: MatDialog,
    private errorService: ErrorService
  ) {
    this.storage = storage;
    this.router = router;
    this.dialog = dialog;
  }

  intercept(request: HttpRequest<any>, next: HttpHandler) {
    const nextRequest = request.clone();

    return next.handle(nextRequest).pipe(
      tap({
        error: (httpErrorResponse: HttpErrorResponse) => {
          console.log(httpErrorResponse);
          if (httpErrorResponse.status === 500) {
            let isExpectedError = false;
            if (httpErrorResponse.error && httpErrorResponse.error.exception) {
              isExpectedError =
                httpErrorResponse.error.exception.indexOf(
                  'DataIntegrityViolationException'
                ) !== -1
                  ? true
                  : false;
            }

            let typeOfError = 'default';

            if (isExpectedError) {
              if (nextRequest.method.toLowerCase() === 'delete') {
                typeOfError = 'delete';
              } else if (nextRequest.method.toLowerCase() === 'post') {
                typeOfError = 'post';
              }
            }

            this.openDialog(typeOfError, httpErrorResponse);
          } else if (
            httpErrorResponse.status === 400 &&
            !ERROR_MESSAGE_TYPES[httpErrorResponse.error.exception] &&
            !(
              httpErrorResponse.error &&
              httpErrorResponse.error.length > 0 &&
              httpErrorResponse.error[0].message
            ) && !(httpErrorResponse.error instanceof Blob && httpErrorResponse.error.type === 'application/json') &&
            (httpErrorResponse.error.message !== "UnauthorizedException") && (httpErrorResponse.error.message !== InsuranceErrors.EUROINS_ERROR)
          ) {
            const typeOfError = 'default';
            this.openDialog(typeOfError, httpErrorResponse);
          }
        },
      })
    );
  }

  openDialog(type: string, errorMessage: HttpErrorResponse) {
    if (errorMessage?.url === "https://face-recognition.production.aws.smilecredit.bg/verify") { // TODO: remove when refactoring face-recognition
      console.error('Error message was skipped')
      return
    }
    const dialogRef = this.dialog.open(ErrorDialogComponent, {
      data: {
        type: type,
        errorMessage: errorMessage,
      },
      minWidth: '650px'
    });
  }
}
