import { NgModule } from '@angular/core';
import { SummaryGroupToTranslationPipe } from './pipes/summary-group-to-translation.pipe';
import { ToCustomItfgAmountPipe } from './pipes/to-custom-itfg-amount.pipe';
import { DisplayNationalityListPipe } from './pipes/display-nationality-list.pipe';
import {NssiContractReasonPipe} from '@app/main/identity-reports/pipes/nssi-contract-reason.pipe';
import {ReportCacheRegisterPipe} from '@app/main/identity-reports/pipes/report-cache-register.pipe';

@NgModule({
  declarations: [
    SummaryGroupToTranslationPipe,
    ToCustomItfgAmountPipe,
    DisplayNationalityListPipe,
    NssiContractReasonPipe,
    ReportCacheRegisterPipe
  ],
  exports: [
    SummaryGroupToTranslationPipe,
    ToCustomItfgAmountPipe,
    DisplayNationalityListPipe,
    NssiContractReasonPipe,
    ReportCacheRegisterPipe
  ],
  imports: [],
})
export class IdentityReportsPipesModule {}
