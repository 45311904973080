import {
  Component,
  OnInit,
  Input,
  ViewContainerRef,
  Output,
  EventEmitter,
  SimpleChanges,
  OnChanges,
  OnDestroy,
} from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  UntypedFormControl,
  ValidatorFn,
} from '@angular/forms';
import { ClientDocument } from '../../../../core/types/client-document';
import { TranslateService } from '@ngx-translate/core';
import { DialogProviderService } from '@app/core/services/dialog-provider.service';
import { FormComponentData } from '../../../../core/types/form-component-data';
import { ClientService } from '../../../../core/services/client.service';
import { Client } from '../../../../core/types/client';
import { saveAs } from 'file-saver';
import { LoaderService } from '../../../../core/services/loader.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'itfg-document-edit',
  templateUrl: './document-edit.component.html',
  styleUrls: ['./document-edit.component.scss'],
})
export class DocumentEditComponent implements OnInit, OnChanges, OnDestroy {
  @Input() data: any;
  @Input() client: Client;
  @Output() dataChanged = new EventEmitter<FormComponentData>();
  @Output() deleteClientDocument = new EventEmitter<ClientDocument>();
  documentsDataForm: UntypedFormGroup;
  listOfDocuments: any[];
  USER_DOCUMENT_TEXT: string;
  MAX_FILE_NAME_LENGTH: number;
  showLoader: boolean;
  isFormDirty: boolean;
  _unsubscribe = new Subject<void>();


  constructor(
    public formBuilder: UntypedFormBuilder,
    public translateService: TranslateService,
    public _viewContainerRef: ViewContainerRef,
    public _dialogService: DialogProviderService,
    protected clientService: ClientService,
    public loaderService: LoaderService
  ) {
    this.USER_DOCUMENT_TEXT = 'userDocument_';
    this.MAX_FILE_NAME_LENGTH = 64;
    this.isFormDirty = false;
  }

  ngOnInit() {
    this.watchForFormChanges();

    // this.setInitialData();
    this.clientService.onDataChange
      .pipe(takeUntil(this._unsubscribe))
      .subscribe(data => {
        this.data = data;
        this.mapInputData(this.data);
      });

    // this.loaderService.status.subscribe((value: boolean) => {
    //   this.showLoader = value;
    // });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.data) {
      this.setInitialData();
    }
  }

  setInitialData() {
    this.documentsDataForm = this.createDocumentsDataForm();

    this.watchForFormChanges();
    this.mapInputData(this.data);
  }

  createDocumentsDataForm() {
    const fb = this.formBuilder;
    return fb.group({});
  }

  addDocumentField() {
    this.isFormDirty = true;
    const documentsListLastElement = this.listOfDocuments[
      this.listOfDocuments.length - 1
    ];
    const elementIndex =
      this.listOfDocuments.length < 1 ? 0 : documentsListLastElement.index + 1;
    this.listOfDocuments.push({ name: '', id: null, index: elementIndex });
    this.addControlByIndex(
      this.documentsDataForm,
      this.USER_DOCUMENT_TEXT + elementIndex,
      ''
    );
  }

  addControlByIndex(
    form: UntypedFormGroup,
    controlName: string,
    value: any,
    validators: ValidatorFn[] = []
  ) {
    const valueControl: UntypedFormControl = this.formBuilder.control(
      value,
      validators
    );
    form.addControl(controlName, valueControl);
  }

  deleteDocumentRow(clientDocument: string[], index: number) {
    index = this.listOfDocuments.length - index - 1; // TODO: Refactor
    const selectedControl = this.documentsDataForm.controls[
      this.USER_DOCUMENT_TEXT + this.listOfDocuments[index].index
    ];
    this.translateService
      .get(
        [
          'clients.deleteDocument',
          'global.confirm',
          'global.cancel',
          'global.delete',
        ],
        {
          document:
            selectedControl.value ?? '',
        }
      )
      .pipe(takeUntil(this._unsubscribe))
      .subscribe(translation => {
        const dialogConfig = {
          message: translation['clients.deleteDocument'],
          disableClose: false,
          viewContainerRef: this._viewContainerRef,
          title: translation['global.delete'],
          cancelButton: translation['global.cancel'],
          acceptButton: translation['global.confirm'],
        };
        if (selectedControl?.value && this.listOfDocuments.find(d => d.name === selectedControl.value)) {
          this._dialogService
            .openConfirm(dialogConfig)
            .afterClosed()
            .pipe(takeUntil(this._unsubscribe))
            .subscribe((accept: boolean) => {
              if (accept) {
                if (this.listOfDocuments[index].id) {
                  this.deleteClientDocument.emit(this.listOfDocuments[index]);
                }

                this.documentsDataForm.removeControl(
                  this.USER_DOCUMENT_TEXT + this.listOfDocuments[index].index
                );
                this.listOfDocuments.splice(index, 1);
              }
            });
        } else {
          this.isFormDirty = false;
          this.documentsDataForm.removeControl(
            this.USER_DOCUMENT_TEXT + this.listOfDocuments[index].index
          );
          this.listOfDocuments.splice(index, 1);
        }
      });
  }

  mapInputData(dataToMap) {
    let data: any[];
    this.isFormDirty = false;

    if (!dataToMap || dataToMap.length === 0) {
      this.listOfDocuments = [];
      return;
    } else {
      data = dataToMap;
      // this.documentsDataForm.disable();
      // this.documentsDataForm.reset();
      this.listOfDocuments = [];
    }

    for (let i = 0; i < dataToMap.length; i++) {
      this.listOfDocuments.push({
        name: dataToMap[i].name,
        id: dataToMap[i].id,
        index: i,
      });

      if (
        this.documentsDataForm.controls.hasOwnProperty(
          this.USER_DOCUMENT_TEXT + i
        )
      ) {
        this.documentsDataForm.patchValue({
          [this.USER_DOCUMENT_TEXT + i]: dataToMap[i].name,
        });
      } else {
        this.addControlByIndex(
          this.documentsDataForm,
          this.USER_DOCUMENT_TEXT + i,
          dataToMap[i].name
        );
      }
    }
  }

  watchForFormChanges() {
    this.documentsDataForm.valueChanges
      .pipe(takeUntil(this._unsubscribe))
      .subscribe(value => {
        const mapedValue = this.mapOutputData(value);
        this.documentsDataForm.markAsPristine();
        this.dataChanged.emit({
          valid: this.documentsDataForm.valid,
          data: mapedValue,
          dirty: this.isFormDirty,
        });
      });
  }

  mapOutputData(valueToMap): any[] {
    const mapedData: any[] = [];

    for (let i = 0; i < this.listOfDocuments.length; i++) {
      const mapedElement = {
        document:
          valueToMap[this.USER_DOCUMENT_TEXT + this.listOfDocuments[i].index],
        id: this.listOfDocuments[i].id,
      };

      if (valueToMap[this.USER_DOCUMENT_TEXT + this.listOfDocuments[i].index]) {
        mapedData.push(mapedElement);
      }
    }

    return mapedData;
  }

  viewDocumentClicked(event: Event, document) {
    event.stopPropagation();
  }

  downloadDocumentClicked(event: Event, document) {
    event.stopPropagation();

    this.clientService
      .renderClientDocument(this.client.id, document.id)
      .pipe(takeUntil(this._unsubscribe))
      .subscribe(doc => {
        const blob = new Blob(<any>[doc]);
        saveAs(blob, document.name);
      });
  }

  onFileSelected(file: File, control: UntypedFormControl) {
    control.patchValue(file);
  }

  inputControl(event) {
    if (!this.isFormDirty) {
      event.preventDefault();
    }
  }

  ngOnDestroy() {
    this._unsubscribe.next();
    this._unsubscribe.complete();
  }
}
