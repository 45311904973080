import { ContactPhone } from './contact-phone';
import { Address } from './address';
import { Education } from './education';
import { EmploymentType } from './employment-type';
import { HouseholdType } from './household-type';
import { PoliceDepartment } from './police-department';
import { MaritalStatus } from './marital-status';
import { ClientPartner } from './client-partner';
import { TemporaryPassword } from './temporary-password';
import { Brand } from './brand';
import { ClientStatus } from './client-status';
import { Tag } from './tag';

export class Client {
  adultHeirs?: number = null;
  bankTransferSalary?: boolean = null;
  brand?: Brand = null;
  carsCount?: number = null;
  carsYears = null;
  childHeirs?: number = null;
  civilId = null;
  facebookId?: string = null;
  googleId?: string = null;
  contactPhones?: ContactPhone[] = null;
  currentAddress: Address = null;
  education: Education | string = null;
  email = null;
  emailConfirmed?: boolean = null;
  employerId = null;
  employerName = null;
  employmentType: EmploymentType | string = null;
  extraIncome?: number = null;
  extraIncomeNotes = null;
  familyMembers?: number = null;
  firstName = null;
  fullName = null;
  id?: number = null;
  idCardAddress?: Address = null;
  idCardIssuedDate = null;
  idCardVerified?: boolean = false;
  idCardIssuer?: PoliceDepartment = null;
  idCardNumber = null;
  nationality?: any = null;
  secondNationality?: any = null;
  ip = null;
  lastName = null;
  maritalStatus?: MaritalStatus = null;
  middleName = null;
  mobile = null;
  mobileConfirmed?: boolean = null;
  partner?: ClientPartner = null;
  password = null;
  salary?: number = null;
  status?: ClientStatus = null;
  temporaryPassword?: TemporaryPassword = null;
  workAddress?: Address = null;
  workExperience = null;
  workExperienceTotal = null;
  workPosition = null;
  pep = false;
  loyaltyEnabled?: boolean = false;
  tags?: Tag[] = [];

  constructor(partial: Partial<Client> = {}) {
    Object.assign(this, partial);
  }
}

export interface ClientOptionsMenuConfigParams {
  showCreateNewCredit?: boolean;
  showCommunication?: boolean;
  showClaims?: boolean;
  showCreditsHistory?: boolean;
  showCCR?: boolean;
  showNssiReports?: boolean;
  showMiReports?: boolean;
  showRelatedPeople?: boolean;
  showClientHistory?: boolean;
}
export enum ExternalClientDataTypes {
  MI = 'mi',
  CCR = 'ccr',
  REF = 'ref'
}
export class ClientOptionsMenuConfig {
  showCreateNewCredit = true;
  showCommunication = true;
  showClaims = true;
  showCreditsHistory = true;
  showCCR = true;
  showNssiReports = true;
  showMiReports = true;
  showRelatedPeople = true;
  showClientHistory = true;

  constructor(params: ClientOptionsMenuConfigParams = {}) {
    Object.assign(this, params);
  }
}

export class ClientLastLogin {
  id: number;
  ip: string;
  time: string;
  user: Client;
}
