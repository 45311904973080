import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from '../../lib/material.module';
import { SharedModule } from '../../shared/shared.module';
import { FilterModule } from '../filter/filter.module';
import { BlacklistEditComponent } from './blacklist-edit/blacklist-edit.component';
import { BankAccountsDataComponent } from './client-edit/bank-accounts-data/bank-accounts-data.component';
import { ClientAddForNewCreditComponent } from './client-edit/client-add-for-new-credit.component';
import { ClientEditComponent } from './client-edit/client-edit.component';
import { ContactDataEditComponent } from './client-edit/contact-data-edit/contact-data-edit.component';
import { DocumentEditComponent } from './client-edit/document-edit/document-edit.component';
import { OccupationStatusEditComponent } from './client-edit/occupation-status-edit/occupation-status-edit.component';
import { PersonalDataEditComponent } from './client-edit/personal-data-edit/personal-data-edit.component';
import { PersonalIdDataEditComponent } from './client-edit/personal-id-data-edit/personal-id-data-edit.component';
import { ProfileDataEditComponent } from './client-edit/profile-data-edit/profile-data-edit.component';
import { StatusEditComponent } from './client-edit/status-edit/status-edit.component';
import { BlacklistTypesTranslationPipe } from './pipes/blacklist-types-translation.pipe';
import { ClientFullNamePipe } from './pipes/client-full-name.pipe';
import { RelationTypeToClientPropertyPipe } from './pipes/relation-type-to-client-property.pipe';
import { RelationTypesTranslationPipe } from './pipes/relation-types-translation.pipe';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTableModule } from '@angular/material/table';
import { ClientCreditsHistoryComponent } from './client-credits-history/client-credits-history.component';
import { RouterModule } from '@angular/router';
import { CreditPipesModule } from '../credit/credit-pipes.module';
import { ClientCcrComponent } from './client-ccr/client-ccr.component';
import { ClientMiComponent } from './client-mi/client-mi.component';
import { ClientNssiComponent } from './client-nssi/client-nssi.component';
import { ClientRelatedPeopleComponent } from './client-related-people/client-related-people.component';
import { ClientCommunicationComponent } from './client-communication/client-communication.component';
import { ClientClaimListComponent } from './client-claim/client-claim-list/client-claim-list.component';
import { ClaimModule } from '../claim/claim.module';
import { StatusDataEditComponent } from './client-edit/status-data-edit/status-data-edit.component';
import { ClientStatusToTranslationPipe } from './pipes/client-status-to-translation.pipe';
import { CreditStatusIconModule } from '../credit/credit-status-icon/credit-status-icon.module';
import { ConversationContactStatusModule } from '../credit/credit-edit/credit-communication/conversation-contacted-status/conversation-contact-status.module';
import { ClientOptionsMenuModule } from './client-edit/client-options-menu/client-options-menu.module';
import { CdkTableModule } from '@angular/cdk/table';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { AdministratorEmailToDisplayTextPipe } from './pipes/administrator-email-to-display-text.pipe';
import { MatDialogModule } from '@angular/material/dialog';
// import { CreditEmailPreviewComponent } from '../credit/credit-edit/credit-communication/credit-email-preview/credit-email-preview.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { IncompleteClientListComponent } from './incomplete-client-list/incomplete-client-list.component';
import { CreditNoteEditModule } from '../credit/credit-edit/credit-communication/credit-note-edit/credit-note-edit.module';
import { ColumnModule } from '../column/column.module';
import { IdentityReportsModule } from '../identity-reports/identity-reports.module';
import { IdentityReportsService } from '../../core/services/identity-reports.service';
import { IdentityReportsPipesModule } from '../identity-reports/identity-reports-pipes.module';
import { DataTableModule } from '@app/core/components/data-table/data-table.module';
import { LoyaltyPointsFlowComponent } from './loyalty-points/loyalty-points-flow/loyalty-points-flow.component';
import { VerificationListComponent } from './verification/verification-list/verification-list.component';
import { MatBadgeModule } from '@angular/material/badge';
import { ClientAddBankAccountComponent } from './client-edit/add-bank-account-modal/client-add-bank-account.component';
import { TagModule } from '../administration/tags/tag.module';
import { TagsModule } from '../tags/tags.module';
import { BulkTaggingComponent } from './bulk-tagging/bulk-tagging.component';
import { ListBaseModule } from '../list-base/list-base.module';
import { EmailViewComponent } from './client-communication/client-email/client-email.component';
import { EmailContentPreviewComponent } from './client-communication/client-email/email-content-preview/email-content-preview.component';
import { MatIconModule } from '@angular/material/icon';
import { ClientSmsComponent } from './client-communication/client-sms/client-sms.component';
import { SendSmsDialogComponent } from './client-communication/client-sms/send-sms-dialog/send-sms-dialog.component';
import { ClientConversationComponent } from './client-communication/client-conversation/client-conversation.component';
import { CompanyPhonesComponent } from './client-edit/contact-data-edit/company-phones/company-phones.component';
import { SingleCompanyPhoneComponent } from './client-edit/contact-data-edit/single-company-phone/single-company-phone.component';
import { BulkLoyaltyPointsComponent } from './bulk-loyalty-points/bulk-loyalty-points.component';
import { MatGridListModule } from '@angular/material/grid-list';
import { GraphModule } from '@app/core/components/graph/graph.module';
import { EventLogAddContactDialogComponent } from '../main-layout/right-drawer/event-log/event-log-add-contact/event-log-add-contact-dialog.component';
import {
  ClientCcrChooseSourceComponent
} from '@app/main/client/client-ccr/client-ccr-choose-source/client-ccr-choose-source.component';
import { CallStatusIconModule } from '../client-communications/call-status-icon/call-status-icon.module';
import {
  ClientCcrParseRequestsReportComponent
} from '@app/main/client/client-ccr/client-ccr-parse-requests-report/client-ccr-parse-requests-report/client-ccr-parse-requests-report.component';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    DataTableModule,
    MatProgressSpinnerModule,
    RouterModule,
    SharedModule,
    FilterModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    CreditPipesModule,
    MatExpansionModule,
    ClaimModule,
    CreditStatusIconModule,
    ConversationContactStatusModule,
    ClientOptionsMenuModule,
    MatTableModule,
    CdkTableModule,
    ScrollingModule,
    MatDialogModule,
    AngularEditorModule,
    CreditNoteEditModule,
    MatButtonToggleModule,
    TagsModule,
    TagModule,
    ColumnModule,
    IdentityReportsModule,
    IdentityReportsPipesModule,
    DataTableModule,
    MatBadgeModule,
    ListBaseModule,
    MatIconModule,
    GraphModule,
    MatButtonToggleModule,
    MatGridListModule,
    CallStatusIconModule,
  ],
  declarations: [
    BankAccountsDataComponent,
    BlacklistTypesTranslationPipe,
    ContactDataEditComponent,
    DocumentEditComponent,
    OccupationStatusEditComponent,
    PersonalDataEditComponent,
    PersonalIdDataEditComponent,
    ProfileDataEditComponent,
    StatusEditComponent,
    ClientEditComponent,
    ClientFullNamePipe,
    ClientAddForNewCreditComponent,
    RelationTypeToClientPropertyPipe,
    RelationTypesTranslationPipe,
    BlacklistEditComponent,
    StatusDataEditComponent,
    ClientCreditsHistoryComponent,
    ClientCcrComponent,
    ClientCcrChooseSourceComponent,
    ClientCcrParseRequestsReportComponent,
    ClientNssiComponent,
    ClientMiComponent,
    ClientRelatedPeopleComponent,
    ClientCommunicationComponent,
    ClientClaimListComponent,
    ClientStatusToTranslationPipe,
    AdministratorEmailToDisplayTextPipe,
    IncompleteClientListComponent,
    LoyaltyPointsFlowComponent,
    VerificationListComponent,
    ClientAddBankAccountComponent,
    BulkTaggingComponent,
    BulkLoyaltyPointsComponent,
    ClientConversationComponent,
    EmailViewComponent,
    ClientSmsComponent,
    EmailContentPreviewComponent,
    SendSmsDialogComponent,
    EventLogAddContactDialogComponent,
    CompanyPhonesComponent,
    SingleCompanyPhoneComponent,
  ],
  exports: [
    BlacklistTypesTranslationPipe,
    ClientEditComponent,
    ClientCreditsHistoryComponent,
    ClientAddForNewCreditComponent,
    ClientFullNamePipe,
    RelationTypeToClientPropertyPipe,
    RelationTypesTranslationPipe,
    BlacklistEditComponent,
    StatusEditComponent,
    ClientCcrComponent,
    ClientCcrChooseSourceComponent,
    ClientCcrParseRequestsReportComponent,
    ClientNssiComponent,
    ClientMiComponent,
    ClientRelatedPeopleComponent,
    ClientStatusToTranslationPipe,
    AdministratorEmailToDisplayTextPipe,
    ClientConversationComponent,
    EmailViewComponent,
    ClientSmsComponent,
    EmailContentPreviewComponent,
    SendSmsDialogComponent,
    ContactDataEditComponent,
    EventLogAddContactDialogComponent,
    CompanyPhonesComponent,
    SingleCompanyPhoneComponent,
  ],
  providers: [IdentityReportsService],
})
export class ClientComponentModule { }
