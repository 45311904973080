import { filter, debounceTime, mergeMap, takeUntil } from 'rxjs/operators';
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
  OnDestroy,
} from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';

import { TranslateService } from '@ngx-translate/core';
import { ClientService } from '../../../../core/services/client.service';
import { PoliceDepartment } from '../../../../core/types/police-department';
import { CityService } from '../../../../core/services/city.service';
import { CountryService } from '../../../../core/services/country.service';
import { FormComponentData } from '../../../../core/types/form-component-data';
import { Utils } from '../../../../core/utils/utils';
import { IdentifierValidation } from '../../../../core/validation/identifier-validation';
import { CompareValidation } from '../../../../core/validation/comparison-validation';
import { RequiredValidation } from '../../../../core/validation/required-validation';
import {
  IdCardVerificationName,
  IdCardVerificationOptions,
  IdCardVerificationOptionType,
} from '../../../../core/types/id-card-verified';
import { AutoCompleteValidation } from '../../../../core/validation/autocomplete-validation';
import { LanguageValidation } from '../../../../core/validation/language-validation';
import { City } from '../../../../../app/core/types/city';
import { Subject } from 'rxjs';

@Component({
  selector: 'itfg-personal-id-data-edit',
  templateUrl: './personal-id-data-edit.component.html',
  styleUrls: ['./personal-id-data-edit.component.scss'],
})
export class PersonalIdDataEditComponent implements OnInit, OnChanges, OnDestroy {
  @Input() data: any;
  @Input() clientId: number;
  @Output() dataChanged = new EventEmitter<FormComponentData>();

  profileDataForm: UntypedFormGroup;
  policeDepartments: Array<PoliceDepartment>;
  idCityList: any[];
  currentCityList: any[];
  idCardVerificationOptionsList: IdCardVerificationOptions;
  menuIdCardVerificationOptionsList: IdCardVerificationOptionType[];
  clientIdVerification: IdCardVerificationOptionType;

  formComponentData: FormComponentData = {} as FormComponentData;
  policeDeparment: PoliceDepartment = {} as PoliceDepartment;

  formDataFromParent: any;
  dateIssuedLabel: string;
  _unsubscribe = new Subject<void>();


  public idCardVerificationStyleMap = {
    [IdCardVerificationName.VALID]: {
      color: 'green',
      icon: 'check_box',
    },
    [IdCardVerificationName.INVALID]: {
      color: '#e53935',
      icon: 'indeterminate_check_box',
    },
    [IdCardVerificationName.UNVERIFIED]: {
      color: '#FED020',
      icon: 'warning',
    },
  };

  constructor(
    public formBuilder: UntypedFormBuilder,
    public clientService: ClientService,
    public cities: CityService,
    public countries: CountryService,
    public translate: TranslateService
  ) {
    this.formDataFromParent = {};
    this.idCardVerificationOptionsList = new IdCardVerificationOptions();
  }

  ngOnInit() {
    this.getPoliceDepartmentData();
    this.getTranslations();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.data) {
      this.data = changes.data.currentValue;
    }
    this.setInitialData();
    this.clientIdVerification = this.idCardVerificationOptionsList.optionsList.filter(
      option => option.value === this.data.idCardVerified
    )[0];
  }

  setInitialData() {
    this.setInnerEntitiesForClientsForm(this.data);
    this.profileDataForm = this.createEditClientForm();
    this.watchForFormChanges();
    this.watchForIdCityInput();
    this.watchForCurrentCityInput();
  }

  getTranslations() {
    this.translate
      .get(['clients.selectDataLabel', 'clients.idDateOfIssue'])
      .pipe(takeUntil(this._unsubscribe))
      .subscribe(translation => {
        this.dateIssuedLabel = translation['clients.idDateOfIssue'];
      });
  }

  setInnerEntitiesForClientsForm(data) {
    // set correct address
    if (data.idCardAddress) {
      // address Name
      if (data.idCardAddress.address) {
        this.formDataFromParent.idCardAddress = data.idCardAddress.address;
      } else {
        this.formDataFromParent.idCardAddress = '';
      }

      if (data.idCardAddress.city) {
        this.formDataFromParent.idCardCity = data.idCardAddress.city;
      } else {
        this.formDataFromParent.idCardCity = '';
      }
    } else {
      this.formDataFromParent.idCardAddress = '';
      this.formDataFromParent.idCardCity = '';
    }

    if (data.currentAddress) {
      if (data.currentAddress.address) {
        this.formDataFromParent.currentAddress = data.currentAddress.address;
      } else {
        this.formDataFromParent.currentAddress = '';
      }

      if (data.currentAddress.city) {
        this.formDataFromParent.currentCity = data.currentAddress.city;
      } else {
        this.formDataFromParent.currentCity = '';
      }
    } else {
      this.formDataFromParent.currentAddress = '';
      this.formDataFromParent.currentCity = '';
    }

    if (data.idCardIssuer && data.idCardIssuer.id) {
      this.formDataFromParent.idCardIssuerID = data.idCardIssuer.id;
    } else {
      this.formDataFromParent.idCardIssuer = '';
    }
  }

  createEditClientForm() {
    const fb = this.formBuilder;
    return fb.group(
      {
        idCardNumber: [
          this.data.idCardNumber,
          [IdentifierValidation.isValidIdCardNumber],
        ],
        idCardIssuerID: [this.formDataFromParent.idCardIssuerID],
        idCardVerified: [this.data.idCardVerified === null ? null : this.data.idCardVerified],
        idCardIssuedDate: [
          this.data.idCardIssuedDate === '' ||
            this.data.idCardIssuedDate === null
            ? null
            : new Date(this.data.idCardIssuedDate),
          [CompareValidation.pastDate],
        ],
        idCardCity: [
          this.formDataFromParent.idCardCity,
          [
            AutoCompleteValidation.isAutocompleteValid(
              'lacksLatin',
              'name',
              LanguageValidation.lacksLatin
            ),
            AutoCompleteValidation.isAutocompleteValidObject(
              'notValidCity',
              'name'
            ),
          ],
        ],
        idCardAddressName: [this.formDataFromParent.idCardAddress],
        currentCity: [
          this.formDataFromParent.currentCity,
          [
            AutoCompleteValidation.isAutocompleteValid(
              'lacksLatin',
              'name',
              LanguageValidation.lacksLatin
            ),
            AutoCompleteValidation.isAutocompleteValidObject(
              'notValidCity',
              'name'
            ),
          ],
        ],
        currentAddressName: [this.formDataFromParent.currentAddress],
      },
      {
        validator: [
          RequiredValidation.fieldDependsOn(
            'idCardAddressName',
            ['idCardCity'],
            'cityRequiredForAddress'
          ),
          RequiredValidation.fieldDependsOn(
            'currentAddressName',
            ['currentCity'],
            'currentCityRequiredForAddress'
          ),
        ],
      }
    );
  }

  watchForIdCityInput() {
    const location = this.profileDataForm.controls.idCardCity;
    const cityInputChange$ = location.valueChanges;

    cityInputChange$
      .pipe(
        filter(value => value === ''),
        takeUntil(this._unsubscribe)
      )
      .subscribe(() => (this.idCityList = []));

    cityInputChange$
      .pipe(
        debounceTime(700),
        filter(value => value !== ''),
        mergeMap(value => this.cities.getCitiesStartingWith(value)),
        takeUntil(this._unsubscribe)
      )
      .subscribe(idCityList => {
        this.idCityList = idCityList;
      });
  }

  watchForCurrentCityInput() {
    const locationCurrentCity = this.profileDataForm.controls.currentCity;
    const currentCityInputChange$ = locationCurrentCity.valueChanges;

    currentCityInputChange$
      .pipe(
        filter(value => value === ''),
        takeUntil(this._unsubscribe)
      )
      .subscribe(() => (this.currentCityList = []));

    currentCityInputChange$
      .pipe(
        debounceTime(700),
        filter(value => value !== ''),
        mergeMap(value => this.cities.getCitiesStartingWith(value)),
        takeUntil(this._unsubscribe)
      )
      .subscribe(currentCityList => {
        this.currentCityList = currentCityList;
      });
  }

  getPoliceDepartmentData() {
    this.clientService.getPoliceDepartmentList()
      .pipe(takeUntil(this._unsubscribe))
      .subscribe(val => {
        this.policeDepartments = val;
      });
  }

  mapCityToDisplayValue(city) {
    return city ? `${city.type}${city.name} - ${city.municipality.name}` : '';
  }

  watchForFormChanges() {
    this.profileDataForm.valueChanges
      .pipe(takeUntil(this._unsubscribe))
      .subscribe(val => {
        this.formComponentData.valid = this.profileDataForm.valid;
        this.formComponentData.dirty = this.profileDataForm.dirty;
        this.formComponentData.data = this.profileDataForm.value;
        // this.formComponentData.data = Utils.removeValues(this.formComponentData.data, '');

        // Set correct date
        if (this.formComponentData.data.idCardIssuedDate) {
          // set correct date handling time difference
          const timeDifference = this.formComponentData.data.idCardIssuedDate.getTimezoneOffset();
          const correctDate =
            this.formComponentData.data.idCardIssuedDate.getTime() -
            timeDifference * 60000;
          const correctDateObj = new Date(correctDate);
          this.formComponentData.data.idCardIssuedDate = correctDateObj;
        }

        // set correct police dep
        if (
          this.formComponentData.data.idCardIssuerID &&
          this.policeDepartments
        ) {
          Utils.replaceIdWithFullObject(
            this.policeDepartments,
            'id',
            this.formComponentData,
            'idCardIssuerID',
            'idCardIssuer'
          );
        }

        // prepare ID card address

        if (this.formComponentData.data.idCardCity) {
          this.formComponentData.data['idCardAddress'] = this.data.idCardAddress
            ? this.data.idCardAddress
            : {};
          this.formComponentData.data.idCardAddress[
            'city'
          ] = this.profileDataForm.value.idCardCity;
          this.formComponentData.data.idCardAddress[
            'address'
          ] = this.profileDataForm.value.idCardAddressName;
        } else {
          this.formComponentData.data['idCardAddress'] = null;
        }
        delete this.formComponentData.data['idCardCity'];
        delete this.formComponentData.data['idCardAddressName'];

        // prepare current address
        if (this.formComponentData.data.currentCity) {
          this.formComponentData.data['currentAddress'] = this.data.currentAddress
            ? this.data.currentAddress
            : {};
          this.formComponentData.data.currentAddress[
            'city'
          ] = this.profileDataForm.value.currentCity;
          this.formComponentData.data.currentAddress[
            'address'
          ] = this.profileDataForm.value.currentAddressName;
        } else {
          this.formComponentData.data['currentAddress'] = null;
        }
        delete this.formComponentData.data['currentCity'];
        delete this.formComponentData.data['currentAddressName'];

        this.dataChanged.emit(this.formComponentData);
      });
  }

  verificationOptionChanged(verificationOption) {
    this.clientIdVerification = verificationOption;

    this.profileDataForm.markAsTouched();
    this.profileDataForm.markAsDirty();
    this.profileDataForm
      .get('idCardVerified')
      .setValue(this.clientIdVerification.value);
  }

  idCardVerificationOptionMenuOpened() {
    this.menuIdCardVerificationOptionsList = this.idCardVerificationOptionsList.optionsList.filter(
      status => status.name !== this.clientIdVerification.name
    );
  }

  openLocationMapForCurrentAddress() {
    const currentAddress = this.profileDataForm.get('currentAddressName').value;
    const currentCity = this.profileDataForm.get('currentCity').value;
    const query = this.buildAddressQueryString(currentCity, currentAddress);
    this.openGoogleMaps(query);
  }

  openLocationMapForIdCardAddress() {
    const idCardAddress = this.profileDataForm.get('idCardAddressName').value;
    const idCardCity = this.profileDataForm.get('idCardCity').value;
    const query = this.buildAddressQueryString(idCardCity, idCardAddress);
    this.openGoogleMaps(query);
  }

  buildAddressQueryString(city: City, address: string) {
    let queryString = '';
    if (city) {
      const mappedCity = this.mapCityToDisplayValue(city);
      queryString += [mappedCity, address].join(', ');
    } else {
      queryString += address;
    }
    return queryString;
  }

  openGoogleMaps(query: string) {
    const googleMapsBaseUrl =
      'https://www.google.com/maps/search/?api=1&query=';
    return window.open(googleMapsBaseUrl + query, '_blank');
  }

  ngOnDestroy() {
    this._unsubscribe.next();
    this._unsubscribe.complete();
  }
}
