import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { CallService } from '@app/core/services/call.service';
import { UserDetails } from '@app/core/types/user-details';
import { distinctUntilChanged, filter, mergeMap, Subject, takeUntil } from 'rxjs';
import { EventLogStateService } from '../main-layout/right-drawer/event-log/event-log-state.service';
import { EventLogMode, EventLogHistoryType, EventLogSubheader } from '../main-layout/right-drawer/shared/event-log.enums';
import { Call } from '@app/core/types/call';
import { AdministratorService } from '@app/core/services/administrator.service';
import { Administrator } from '@app/core/types/administrator';
import { EventLogService } from '@app/core/services/event-log.service';

@Component({
  selector: 'itfg-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit, OnDestroy {
  currentlyLoggedOperator: UserDetails;
  administrators: Administrator[];
  _unsubscribe: Subject<void> = new Subject<void>();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private callService: CallService,
    private eventLogStateService: EventLogStateService,
    private administratorService: AdministratorService,
    private eventLogService: EventLogService,
  ) {
  }

  ngOnInit() {
    this.administratorService.administrators$
      .pipe(
        takeUntil(this._unsubscribe),
        filter(administrators => administrators.length > 0),
        distinctUntilChanged(),
        mergeMap((administrators: Administrator[]) => {
          this.administrators = administrators;
          return this.route.queryParamMap;
        }),
        distinctUntilChanged((prev, curr) => prev.get('callId') === curr.get('callId')),
        filter((params: ParamMap) => !!params.get('callId')),
        mergeMap((params: ParamMap) => this.callService.getCallById(Number(params.get('callId')))),
        filter(call => !!call)
      )
      .subscribe((call: Call) => {
        this.eventLogStateService.setState(
          {
            mode: EventLogMode.PBX,
            historyType: EventLogHistoryType.PHONE,
            subheader: EventLogSubheader.PBX,
            phoneNumber: call.phoneNumber,
            call: call,
          },
          { openDrawer: true }
        );

        this.eventLogService.setHighlightedEntityInEventLog(call);
      });
  }

  navigate(url: string, newTab?: boolean) {
    if (newTab) {
      window.open('/#/' + url, '_blank');
    } else {
      this.router.navigate([url]);
    }
  }

  ngOnDestroy() {
    this._unsubscribe.next();
    this._unsubscribe.complete();
  }
}
