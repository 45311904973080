<!-- <mat-form-field flex class="tags-width custom-remove-underline"> -->
  <mat-chip-listbox #chipList>
    <mat-chip *ngFor="let tag of tags"
              class="mat-elevation-z2"
              [selectable]="true"
              [removable]="true"
              (removed)="remove(tag)"
              matTooltipPosition="above"
              matTooltip="{{tag.description}}"
              [ngStyle]="{'background-color': tag.color, 'color': 'white' }">
        {{tag.name}}
      <mat-icon matChipRemove
                *ngxPermissionsOnly="'TAG_DELETE'"
                [ngStyle]="{ 'color': 'white' }">cancel</mat-icon>
    </mat-chip>
    <!-- <mat-icon matPrefix
              matTooltipPosition="above"
              matTooltip="{{'credits.addtTag' | translate}}"
              *ngIf="tags.length < maxCreditTags"
              (click)="focusInput($event)">add</mat-icon> -->
    <!-- <input
      *ngIf="tags.length < maxtTags"
      #tagInput
      [formControl]="tagsCtrl"
      [matAutocomplete]="auto"
      [matChipInputFor]="chipList"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      (matChipInputTokenEnd)="add($event)">
  </mat-chip-list> -->
  <!-- <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
    <mat-option *ngFor="let tag of filteredTagList" [value]="tag">
      {{tag.name}}
    </mat-option>
  </mat-autocomplete> -->
<!-- </mat-form-field> -->
