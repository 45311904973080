import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
  Optional,
  OnDestroy,
  OnChanges,
  SimpleChanges,
  ViewContainerRef,
  Output,
  EventEmitter,
} from '@angular/core';
import {
  BehaviorSubject,
  Observable,
  Subject,
  filter,
  takeUntil,
  tap,
} from 'rxjs';
import { Call, CallStatus, CreateCallDto, HIDDEN_PHONE_NUMBER } from '@app/core/types/call';
import { NoteService } from '@app/core/services/note.service';
import { HttpErrorResponse } from '@angular/common/http';
import { CreateNoteDto, Note, UpdateNoteDto } from '@app/core/types/note';
import { NotificationService } from '@app/core/services/notification.service';
import { Credit } from '@app/core/types/credit';
import { FormControl, UntypedFormGroup } from '@angular/forms';
import {
  MatAutocomplete,
  MatAutocompleteActivatedEvent,
  MatAutocompleteSelectedEvent,
  MatAutocompleteTrigger,
} from '@angular/material/autocomplete';
import { TopicsService } from '@app/core/services/topics.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { EditNoteDialogComponent } from '../edit-note-dialog/edit-note-dialog.component';
import { Topic, TopicNode } from '@app/core/types/topic';
import {
  SlashCase,
  SpecialOption,
  SpecialOptionType,
  NoteMode,
} from './note.types';
import { specialOptions } from './special-options';
import { ReminderEditComponent } from '@app/main/credit/credit-edit/credit-communication/reminder-edit/reminder-edit.component';
import { PaymentPromiseEditComponent } from '@app/main/credit/credit-edit/credit-communication/payment-promise-edit/payment-promise-edit.component';

import { CollectionRequestService } from '@app/core/services/collection-request.service';
import { ReminderService } from '@app/core/services/reminder.service';
import { SessionService } from '@app/core/services/session.service';
import { ErrorService } from '@app/core/services/error.service';
import { PaymentPromiseService } from '@app/core/services/payment-promise.service';
import { PaymentPromise } from '@app/core/types/payment-promise';
import * as moment from 'moment/moment';
import { EventLogStateService } from '../../event-log-state.service';
import { CallService } from '@app/core/services/call.service';
import { TranslateService } from '@ngx-translate/core';
import { Client } from '@app/core/types/client';
import { IConfirmConfig } from '@app/core/types/dialog.type';
import { DialogProviderService } from '@app/core/services/dialog-provider.service';

@Component({
  selector: 'itfg-note',
  templateUrl: './note.component.html',
  styleUrls: ['./note.component.scss'],
})
export class NoteComponent
  implements OnInit, AfterViewInit, OnDestroy, OnChanges
{
  @Input() noteToEdit: Note;
  @Input() call: Call;
  @Input() selectedCredit: Credit;
  @Input() client: Client;
  @Input() openedCredit: Credit;
  @Input() mode: NoteMode = NoteMode.DEFAULT;
  @Input() manualCallForm: UntypedFormGroup;
  @Output() noteSavedAfterCallEnded = new EventEmitter<void>();

  @ViewChild(MatAutocompleteTrigger) trigger: MatAutocompleteTrigger;
  @ViewChild(MatAutocomplete) autocomplete: MatAutocomplete;
  @ViewChild('textarea') textarea: ElementRef;

  SlashCase = SlashCase;
  NoteMode = NoteMode;

  formControl = new FormControl('');
  noteText: string = '';
  activatedOption: TopicNode | SpecialOption;
  selectedTopics: Set<Topic> = new Set();
  topicsTree: TopicNode;
  topicNodesArr: TopicNode[];
  slashCase: SlashCase;
  specialOptions = specialOptions;
  selectedCreditInCollection: boolean;
  openedCreditInCollection: boolean;
  doubleSlashManualFlag = false;
  doubleSlashManualData: string[];
  hiddenMobile: string = HIDDEN_PHONE_NUMBER;
  filteredOptions$ = new BehaviorSubject<TopicNode[]>([]);
  filteredSpecialOptions$ = new BehaviorSubject<SpecialOption[]>([]);
  isSavingNote: boolean = false;
  isNoteSaveHighlighten: boolean = false;
  private _unsubscribe = new Subject<void>();

  constructor(
    private noteService: NoteService,
    private notifications: NotificationService,
    private topicsService: TopicsService,
    @Optional()
    private editNoteDialogRef: MatDialogRef<EditNoteDialogComponent>,
    private dialog: MatDialog,
    private collectionReqService: CollectionRequestService,
    private reminderService: ReminderService,
    private sessionService: SessionService,
    private errorService: ErrorService,
    private paymentPromiseService: PaymentPromiseService,
    private eventLogStateService: EventLogStateService,
    private callService: CallService,
    private translateService: TranslateService,
    private viewContainerRef: ViewContainerRef,
    private dialogProvider: DialogProviderService
  ) {
    this.displayFn = this.displayFn.bind(this);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.selectedCredit &&
      changes.selectedCredit.currentValue?.id !==
        changes.selectedCredit.previousValue?.id &&
      this.selectedCredit
    ) {
      this.collectionReqService
        .get([`credits/${this.selectedCredit.id}/is-in-collection`])
        .pipe(takeUntil(this._unsubscribe))
        .subscribe((bool) => {
          this.selectedCreditInCollection = bool;
        });
    }
    if (
      changes.openedCredit &&
      changes.openedCredit.currentValue?.id !==
        changes.openedCredit.previousValue?.id &&
      this.openedCredit
    ) {
      this.collectionReqService
        .get([`credits/${this.openedCredit.id}/is-in-collection`])
        .pipe(takeUntil(this._unsubscribe))
        .subscribe((bool) => {
          this.openedCreditInCollection = bool;
        });
    }
  }

  ngOnInit() {
    this.formControl.valueChanges
      .pipe(
        takeUntil(this._unsubscribe),
        filter((newValue): newValue is string => typeof newValue === 'string'),
        tap((newValue) => {
          this.noteText = newValue;

          const options = this.filter(newValue);

          if (
            this.slashCase === SlashCase.SINGLE ||
            this.slashCase === SlashCase.NONE
          ) {
            this.filteredOptions$.next(options as TopicNode[]);
          }
          if (this.slashCase === SlashCase.DOUBLE) {
            this.filteredSpecialOptions$.next(options as SpecialOption[]);
          }
          if (!this.activatedOption && options) {
            this.activatedOption = options[0];
          }
        })
      )
      .subscribe();

    this.topicsService.getTree$().subscribe({
      next: (tree) => {
        this.topicsTree = tree;
        this.topicNodesArr = this.flattenTree(tree);
        // When editing a note, set component state and ensure
        // correct topic references for set functionality
        if (this.noteToEdit) {
          this.formControl.setValue(this.noteToEdit.content);
          this.noteToEdit.topics.forEach((topic: Topic) => {
            const topicNodeRef = this.topicNodesArr.find(
              (node) => node.data.id === topic.id
            );
            const topicRef = topicNodeRef.data;

            this.selectedTopics.add(topicRef);
          });
        }
      },
      error: (error) => console.error('Error fetching topics:', error),
    });

    this.noteService.promptSavingOfNote.pipe(takeUntil(this._unsubscribe)).subscribe((res: boolean) => {
        this.isNoteSaveHighlighten = res;
    })
  }

  ngAfterViewInit() {
    // Override the default TAB key behavior to prevent closing the autocomplete panel
    const originalOnKeydownFn = this.autocomplete._keyManager.onKeydown.bind(
      this.autocomplete._keyManager
    );

    this.autocomplete._keyManager.onKeydown = (event: KeyboardEvent) => {
      if (event.key !== 'Tab') {
        originalOnKeydownFn(event);
      }
    };

    if (this.call) {
      setTimeout(() => {
        this.textarea.nativeElement.focus();
      }, 100);
    }
  }

  openPaymentPromiseDialog() {
    const credit = this.selectedCredit || this.openedCredit;

    if (credit) {
      return this.dialog.open(PaymentPromiseEditComponent, {
        data: {
          credit: credit,
        },
        minWidth: '400px',
        restoreFocus: false,
      });
    }
  }

  openReminderDialog() {
    const credit = this.selectedCredit || this.openedCredit;

    if (credit) {
      this.dialog.open(ReminderEditComponent, {
        data: {
          reminder: null,
          credit: credit,
        },
        minWidth: '400px',
        restoreFocus: false,
      });
    }
  }

  filter(value: string) {
    const slashIndex = value.indexOf('/');
    const slash = slashIndex !== -1;

    if (!slash) {
      this.slashCase = SlashCase.NONE;
      this.doubleSlashManualFlag = false;

      return [];
    } else {
      const doubleSlashEndIndex =
        value[slashIndex + 1] === '/' ? slashIndex + 1 : -1;
      const doubleSlash = doubleSlashEndIndex !== -1;

      if (!doubleSlash) {
        const filterValue = this.getTail(value, slashIndex, true);

        this.slashCase = SlashCase.SINGLE;
        this.doubleSlashManualFlag = false;

        return this.handleSingleSlashCase(filterValue);
      } else {
        const filterValue = this.getTail(value, doubleSlashEndIndex, true);

        this.slashCase = SlashCase.DOUBLE;

        return this.handleDoubleSlashCase(filterValue);
      }
    }
  }

  handleSingleSlashCase(value: string) {
    const isPath = value.indexOf('/') !== -1;

    if (!isPath) {
      if (!value) {
        return this.topicsTree.children;
      }

      const isNumeric = !isNaN(parseInt(value)) && isFinite(parseInt(value));

      if (!isNumeric) {
        return this.topicNodesArr.filter((node) => {
          const nodeName = node.data.name.toLowerCase() || '';

          return nodeName.startsWith(value) || nodeName.includes(value);
        });
      } else {
        return this.topicNodesArr.filter((node) =>
          node.data.code.startsWith(value)
        );
      }
    } else {
      const lastSlashIndex = value.lastIndexOf('/');
      const head = this.getHead(value, lastSlashIndex, true);
      const tail = this.getTail(value, lastSlashIndex, true);
      const parentNode = this.topicNodesArr.find(
        (node) => node.data.path.toLowerCase() === head
      );

      return parentNode?.children.filter((node) =>
        node.data.name.toLowerCase().startsWith(tail)
      );
    }
  }

  handleDoubleSlashCase(value: string) {
    if (!value) {
      this.doubleSlashManualFlag = false;

      return this.specialOptions;
    }

    const isPath = value.indexOf('/') !== -1;
    let filterValue: string;

    if (!isPath) {
      filterValue = value;
      this.doubleSlashManualFlag = false;
    } else {
      const dataArr = value.split('/');

      filterValue = dataArr.shift();
      this.doubleSlashManualFlag = true;
      this.doubleSlashManualData = dataArr;
    }

    return this.specialOptions.filter((option) => {
      const name = option.name.toLowerCase();

      return name.startsWith(filterValue) || name.includes(filterValue);
    });
  }

  getDate(dateStr: string, timeStr?: string, toIso = false) {
    if (
      !(dateStr.length === 4 || dateStr.length === 6) ||
      (timeStr && timeStr.length !== 4)
    ) {
      return null;
    }

    const day = +dateStr.substring(0, 2);
    const month = +dateStr.substring(2, 4) - 1;
    let year: number;

    if (dateStr.length === 4) {
      year = +('20' + new Date().getFullYear().toString().slice(-2));
    } else if (dateStr.length === 6) {
      year = +('20' + dateStr.substring(4, 6));
    }

    let hours = 0;
    let minutes = 0;

    if (timeStr) {
      hours = +timeStr.substring(0, 2);
      minutes = +timeStr.substring(2, 4);
    }

    const date = new Date(year, month, day, hours, minutes);

    if (this.isValidDate(date)) {
      if (toIso) {
        return date.toISOString();
      } else {
        return date;
      }
    } else {
      return null;
    }
  }

  addReminderManually(isoDate: string) {
    this.reminderService
      .createReminder$({
        time: isoDate,
        credit: this.selectedCredit?.id || this.openedCredit?.id,
        operatorId: this.sessionService.currentlyLoggedOperator.id,
      })
      .pipe(takeUntil(this._unsubscribe))
      .subscribe({
        next: (res) => {
          this.reminderService.refreshReminder$.next();
          this.notifications.showLocalizedSuccessMessage({
            notificationText: 'communication.reminderSuccessfullyAdded',
          });
        },
        error: (error: HttpErrorResponse) => {
          this.errorService.showSnackbarErrorMessage(error);
        },
      });
  }

  addPaymentPromiseManually(date: string, amount: number) {
    const promise: PaymentPromise = {
      amount,
      credit: this.selectedCredit || this.openedCredit,
      date,
      operatorId: this.sessionService.currentlyLoggedOperator.id,
      operatorEmail: this.sessionService.currentlyLoggedOperator.email,
    };

    this.paymentPromiseService
      .createPaymentPromise$(promise)
      .pipe(takeUntil(this._unsubscribe))
      .subscribe({
        next: (res) => {
          this.paymentPromiseService.onCreditPaymentPromiseDataChange.next(
            true
          );
          this.notifications.showLocalizedSuccessMessage({
            notificationText: 'communication.paymentPromiseSuccessfullyAdded',
          });
        },
        error: (error: HttpErrorResponse) => {
          this.errorService.showSnackbarErrorMessage(error);
        },
      });
  }

  isValidDate(date: Date) {
    return date.toString() !== 'Invalid Date';
  }

  // Retrieve the first part of a string before a specified index
  getHead(str: string, index: number, format = false) {
    const substr = str.substring(0, index);

    if (!format) {
      return substr;
    } else {
      return this.formatString(substr);
    }
  }

  // Retrieve the latter part of a string after a specified index
  getTail(str: string, index: number, format = false) {
    const substr = str.substring(index + 1);

    if (!format) {
      return substr;
    } else {
      return this.formatString(substr);
    }
  }

  // Trim whitespace and lowercase a string
  formatString(str: string) {
    return str.trim().toLowerCase();
  }

  flattenTree(tree: TopicNode) {
    const res = [];

    function flattenNode(node: TopicNode) {
      if (node.data) {
        res.push(node);
      }

      node.children.forEach(flattenNode);
    }

    flattenNode(tree);

    return res;
  }

  addTail(event: KeyboardEvent) {
    event.preventDefault();

    const slashIndex = this.noteText.indexOf('/');
    const text = this.noteText;

    if (slashIndex !== -1 && this.activatedOption) {
      const croppedText = this.getHead(text, slashIndex);
      let path: string;

      if (this.slashCase === SlashCase.SINGLE) {
        const topicNode = this.activatedOption as TopicNode;

        if (topicNode.children.length > 0) {
          path = `/${topicNode.data.path}/`;
        } else {
          path = `/${topicNode.data.path}`;
        }
      }
      if (this.slashCase === SlashCase.DOUBLE) {
        const specialOption = this.activatedOption as SpecialOption;

        path = `//${specialOption.name}/`;
      }

      this.formControl.setValue(croppedText + path);
    }
  }

  navigateForwardWithArrowRight(event: Event, option?: TopicNode): void {
    if (!option) {
      const slashIndex = this.noteText.indexOf('/');
      const text = this.noteText;

      if (slashIndex !== -1 && this.activatedOption) {
        const croppedText = this.getHead(text, slashIndex);
        let path: string;

        if (this.slashCase === SlashCase.SINGLE) {
          const topicNode = this.activatedOption as TopicNode;
          path = `/${topicNode.data.path}`;

          if (topicNode.children.length > 0) {
            path += '/';
          }
        }

        if (this.slashCase === SlashCase.DOUBLE) {
          const specialOption = this.activatedOption as SpecialOption;
          path = `/${specialOption.name}/`;
        }

        this.formControl.setValue(croppedText + path);
      }
    } else {
      let path = `/${option.data.path}`;
      if (option.children.length > 0) {
        path += '/';
      }
      this.formControl.setValue(
        this.formControl.value.replace(/\/+$/, '') + path
      );
    }
  }

  removeTail(event: KeyboardEvent) {
    const pathArr = (this.noteText && this.noteText.split('/')) || [];
    const selStart = this.textarea.nativeElement.selectionStart;
    const selEnd = this.textarea.nativeElement.selectionEnd;
    const textSelected = selStart !== selEnd;

    if (pathArr.length > 2 && textSelected === false) {
      event.preventDefault();

      const isLeaf = !this.noteText.endsWith('/');
      let newPath: string;

      pathArr.pop();
      if (isLeaf) {
        newPath = pathArr.join('/') + '/';
      } else {
        newPath = pathArr.join('/');
      }

      this.formControl.setValue(newPath);
    }
  }

  navigateBackWithArrowLeft(event: KeyboardEvent): void {
    event.stopPropagation();
    event.preventDefault();

    const pathArr = (this.noteText && this.noteText.split('/')) || [];

    if (pathArr.length > 1) {
      if (pathArr[pathArr.length - 1] === '') {
        pathArr.pop();
      }

      pathArr.pop();

      let newPath = pathArr.join('/');
      if (pathArr.length > 1 || newPath.endsWith('/')) {
        newPath = '/' + newPath + '/';
      } else if (pathArr.length === 1) {
        newPath = '/' + pathArr[0];
      } else {
        newPath = '/';
      }

      this.formControl.setValue(newPath.replace(/^\/+/, '/'));
    } else {
      this.formControl.setValue('/');
    }
  }

  onOptionActivated(event: MatAutocompleteActivatedEvent) {
    this.activatedOption = event.option?.value;
  }

  removeTopic(topicToDelete: Topic) {
    this.selectedTopics.delete(topicToDelete);
  }

  onOptionSelected(event: MatAutocompleteSelectedEvent) {
    if (this.slashCase === SlashCase.SINGLE) {
      const topicNode = event.option.value;
      const topic = topicNode.data;

      this.selectedTopics.add(topic);
      this.filteredOptions$.next([]);
      this.activatedOption = null;
    }
    if (this.slashCase === SlashCase.DOUBLE) {
      const optionType = event.option.value.type;

      if (!this.doubleSlashManualFlag) {
        optionType === SpecialOptionType.REMINDER && this.openReminderDialog();
        optionType === SpecialOptionType.PAYMENT_PROMISE &&
          this.openPaymentPromiseDialog();
      } else {
        if (optionType === SpecialOptionType.REMINDER) {
          const dateStr = this.doubleSlashManualData[0];
          const timeStr = this.doubleSlashManualData[1];
          const isoDate = this.getDate(dateStr, timeStr, true) as string;

          if (isoDate) {
            this.addReminderManually(isoDate);
          } else {
            this.notifications.showLocalizedErrorMessage({
              notificationText: 'Невалидни данни',
            });
          }
        }
        if (optionType === SpecialOptionType.PAYMENT_PROMISE) {
          const dateStr = this.doubleSlashManualData[0];
          const amount = +this.doubleSlashManualData[1];
          const date = this.getDate(dateStr);

          if (date && !isNaN(amount)) {
            const formattedDate = moment(date).format('YYYY-MM-DD');

            this.addPaymentPromiseManually(formattedDate, amount);
          } else {
            this.notifications.showLocalizedErrorMessage({
              notificationText: 'Невалидни данни',
            });
          }
        }
      }
      this.filteredSpecialOptions$.next([]);
    }
  }

  displayFn(newValue: any) {
    const prevValue = this.formControl.value;

    if (typeof newValue === 'string') {
      this.noteText = prevValue;

      return prevValue;
    }
    if (typeof newValue === 'object') {
      const firstSlashIndex = prevValue.indexOf('/');
      const newValue = prevValue.substring(0, firstSlashIndex);

      this.noteText = newValue;

      return newValue;
    }
  }

  onSave() {
    if (this.mode === NoteMode.PBX) {
      if (!this.selectedCredit) {
        this.openDialogForNoteWithoutSelectedCredit().subscribe({
          next: () => {
            this.isSavingNote = true;

            if (!this.call) {
              this.createManualCall();
            } else {
              this.createOrUpdateNote();
            }
          },
          
          error: (error) => {
            this.notifications.showLocalizedErrorMessage({
              notificationText: 'pbx.unsuccessfullyCreatedManualCall',
            });
          },
        });
      } else {
        if (!this.call) {
          this.createManualCall();
        } else {
          this.createOrUpdateNote();
        }
      }
    } else {
      this.createOrUpdateNote();
    }
  }

  createOrUpdateNote(call?: Call) {
    const creditId =
      this.mode === NoteMode.PBX
        ? this.selectedCredit?.id
        : this.mode === NoteMode.DEFAULT
          ? this.openedCredit?.id
          : null;
    const clientId =
      this.mode === NoteMode.PBX
        ? this.selectedCredit?.user?.id
        : this.mode === NoteMode.DEFAULT
          ? this.openedCredit?.user?.id || this.client?.id
          : null;

    const noteDto: CreateNoteDto | UpdateNoteDto = {
      creditId: this.noteToEdit ? +this.noteToEdit.creditId : creditId,
      clientId: this.noteToEdit ? +this.noteToEdit.clientId : clientId,
      content: this.noteText.replace(/\s+/g, ' ').trim(),
      call: { id: call ? call.id : this.call ? this.call.id : null },
      topics: Array.from(this.selectedTopics),
    };

    let noteSaveUpdateReq$ = this.noteToEdit
      ? this.noteService.updateNote(this.noteToEdit.id, noteDto)
      : this.noteService.saveNote(noteDto);

    this.handleNoteSaveUpdate(noteSaveUpdateReq$);
  }

  handleNoteSaveUpdate(noteSaveUpdateReq$: Observable<Note>) {
    noteSaveUpdateReq$.subscribe({
      next: (note: Note) => {
        this.isSavingNote = false;
        this.noteService.onNoteChange.next(true);

        if (!this.noteToEdit) {
          this.resetForm();
        } else {
          this.updateForm(note);
          // TODO: Should we close it if there's only a single note to edit but keep it open if there is more than two notes for editing?
          // this.editNoteDialogRef.close();
        }

        const shouldExitCallAfterSave: boolean = this.call && this.call.status !== CallStatus.INITIATED && this.call.status !== CallStatus.ONGOING && this.isNoteSaveHighlighten;
        if (shouldExitCallAfterSave) {
          this.isNoteSaveHighlighten = false;
          this.noteSavedAfterCallEnded.emit();
        }

        this.notifications.showLocalizedSuccessMessage({
          notificationText: this.noteToEdit
            ? 'communication.noteSuccessfullyEdited'
            : 'communication.noteSuccessfullyAdded',
        });
      },
      error: (error: HttpErrorResponse) => {
        this.isSavingNote = false;
        this.isNoteSaveHighlighten = false;
        this.notifications.showLocalizedErrorMessage({
          notificationText: this.noteToEdit
            ? 'communication.noteUnsuccessfullyEdited'
            : 'communication.noteUnsuccessfullyAdded',
        });
      },
    });
  }

  createManualCall() {
    const phoneNumberValue = this.manualCallForm.get('phone').value;
    const formattedPhoneNumber =
      this.eventLogStateService.formatPhoneNumber(phoneNumberValue);

    const callDto: CreateCallDto = {
      phoneNumber: formattedPhoneNumber,
      direction: this.manualCallForm.get('direction').value,
      status: this.manualCallForm.get('status').value,
    };

    this.callService.createManualCall(callDto).subscribe({
      next: (call: Call) => {
        this.createOrUpdateNote(call);

        this.notifications.showLocalizedSuccessMessage({
          notificationText: this.translateService.instant(
            'pbx.successfullyCreatedManualCall',
            {
              phoneNumber:
                call.phoneNumber === this.hiddenMobile
                  ? this.translateService.instant('communication.hiddenNumber')
                  : call.phoneNumber,
            }
          ),
        });
      },

      error: (error: HttpErrorResponse) => {
        this.notifications.showLocalizedErrorMessage({
          notificationText: 'pbx.unsuccessfullyCreatedManualCall',
        });
      },
    });
  }

  hasParent(option: TopicNode): boolean {
    return option.data.path.split('/').length > 1;
  }

  openDialogForNoteWithoutSelectedCredit(): Observable<boolean> {
    const dialogConfig: IConfirmConfig = {
      message: this.translateService.instant(
        'pbx.confirmCreateNoteWithoutCreditMessage'
      ),
      disableClose: false,
      viewContainerRef: this.viewContainerRef,
      title: this.translateService.instant('global.confirm'),
      acceptButton: this.translateService.instant('global.proceed'),
      cancelButton: this.translateService.instant('global.cancel'),
    };

    return this.dialogProvider
      .openConfirm(dialogConfig)
      .afterClosed()
      .pipe(filter((accept: boolean) => accept === true));
  }

  createManualCallAsObservable(): Observable<Call> {
    const formattedPhoneNumber = this.eventLogStateService.formatPhoneNumber(
      this.manualCallForm.get('phoneNumber').value
    );
    const callDto: CreateCallDto = {
      phoneNumber: formattedPhoneNumber,
      direction: this.manualCallForm.get('direction').value,
      status: this.manualCallForm.get('status').value,
    };

    return this.callService.createManualCall(callDto);
  }

  saveNoteFromCallDialog(callId: number, selectedCreditFromDialog?: Credit) {
    if (this.noteText.length > 0 || this.selectedTopics.size > 0) {
      const note = {
        creditId: selectedCreditFromDialog ? selectedCreditFromDialog.id : null,
        content: this.noteText.trim(),
        call: callId,
        topics: Array.from(this.selectedTopics),
      } as CreateNoteDto;

      this.noteService.saveNote(note).subscribe({
        next: (note: Note) => {
          this.noteService.onNoteChange.next(true);

          this.notifications.showLocalizedSuccessMessage({
            notificationText: 'communication.noteSuccessfullyAdded',
          });
        },
        error: (error: HttpErrorResponse) => {
          this.notifications.showLocalizedErrorMessage({
            notificationText: 'communication.noteUnsuccessfullyAdded',
          });
        },
      });
    }
  }

  resetForm() {
    this.formControl.setValue('');
    this.filteredOptions$.next([]);
    this.selectedTopics = new Set();
    this.activatedOption = null;
  }

  updateForm(note: Note) {
    this.formControl.setValue(note.content);
    this.filteredOptions$.next([]);
    note.topics.forEach((topic: Topic) => {
      const topicRef = this.topicNodesArr.find(
        (node) => node.data.id === topic.id
      ).data;
      this.selectedTopics.add(topicRef);
    });
    this.activatedOption = null;
  }

  ngOnDestroy() {
    console.log("Note destroyed!");
    this._unsubscribe.next();
    this._unsubscribe.complete();
  }
}
