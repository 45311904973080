<div layout="column"
     *ngIf="client?.civilId && client?.id"
     class="top-padding-16">
  <h3 class="edit-screen-card-title">
    {{ 'mi.miReport' | translate }}
  </h3>
  <div flex>
    <div
      flex
      layout="row"
      layout-wrap
      layout-align="space-between stretch"
    >
      <div flex="100" flex-gt-md="75" flex-gt-sm="100" flex-gt-xs="100">
        <itfg-reports-info-panel
          [listData]="miListData"
          [reportData]="reportData"
          [reportTime]="requestTime"
          [requesterName]="reportData?.requesterName"
          [metaStatusCode]="''"
          [requestTime]="requestTime"
          (dataChanged)="miDataChanged($event)"
        ></itfg-reports-info-panel>
      </div>

      <div flex layout="row" layout-align="end start" [style.min-height.px]="65">


        <itfg-id-card-status [statusValue]="client?.idCardVerified"
                             [client]="client"
                             *ngIf="miListData?.length > 0 && reportData?.data !== null && isReport"></itfg-id-card-status>


        <button mat-raised-button
                [disabled]="['MI_ADD'] | isUnauthorized | async"
                (click)="getNewMi()">
          {{ 'mi.getNewMiReport' | translate }}
        </button>
      </div>
    </div>
    <div *ngIf="miListData?.length === 0 && !isListData"
         flex
         layout="row"
         layout-align="start"
         class="left-padding-16">
      <h3 class="edit-screen-card-title">{{ 'mi.noMiFiles' | translate }}</h3>
    </div>
    <div *ngIf="reportData?.status != 'success'"
         flex
         layout="row"
         layout-align="start center"
         class="left-padding-16">
      <h4 *ngIf="reportData?.status == 'incorrect_input'"
          class="edit-screen-card-title">{{ 'mi.incorrectDataMsg' |
        translate }}</h4>
      <h4 *ngIf="reportData?.status == 'not_found'"
          class="edit-screen-card-title">{{ 'mi.noMiData' | translate
        }}</h4>
      <h4 *ngIf="reportData?.status == 'server_timeout'"
          class="edit-screen-card-title">{{
        ('reports.serverTimeoutError' | translate: {requestTime: (requestTime | itfgDateFormat)})}}
        <itfg-no-reports-info
          [alertMessage]="{civilId: client?.civilId, type: reportData?.type, source: reportData?.source, status: reportData?.status, time: reportData?.time, data: reportData?.data}">
        </itfg-no-reports-info>
      </h4>
      <h4 *ngIf="reportData?.status == 'server_error'"
          class="edit-screen-card-title">{{ ('reports.serverGenericError'
        | translate: {requestTime: (requestTime | itfgDateFormat)})}}
        <itfg-no-reports-info
          [alertMessage]="{civilId: client?.civilId, type: reportData?.type, source: reportData?.source, status: reportData?.status, time: reportData?.time, data: reportData?.data}">
        </itfg-no-reports-info>
      </h4>
    </div>
    <div>
      <mat-card appearance="outlined"
                class="verification"
                [ngClass]="{
                'mi-verified': !faceVerificationLoading && faceVerification && faceVerification.verified,
                'mi-no-face-verification': faceVerificationLoading || !faceVerification || !faceVerification.verified
            }">
        <mat-card-title class="identity-verification-title">
          <div *ngIf="!faceVerificationLoading && !!faceVerification"
               layout="row"
               layout-align="space-between center">
          <span class="identity-verification-title-text text-upper">{{ (faceVerification.verified ?
            'faceRecognition.verifiedIdentity' : 'faceRecognition.nonVerifiedIdentity') | translate }}</span>
            <mat-icon>{{ faceVerification.verified ? 'verified_user' : 'cancel' }}</mat-icon>
          </div>
          <span *ngIf="faceVerificationLoading || !faceVerification"
                class="identity-verification-title-text text-upper">{{ 'faceRecognition.identity' | translate }}</span>
        </mat-card-title>
        <mat-card-content class="identity-verificatiion-card-content">
          <!-- <ng-template tdLoading
                       [tdLoadingUntil]="!faceVerificationLoading"
                       tdLoadingMode="indeterminate"
                       tdLoadingType="circle"
                       tdLoadingStrategy="overlay"
                       tdLoadingColor="primary"> -->
          <h3 *ngIf="faceVerificationLoading"
              class="pad text-center text-upper">{{ 'faceRecognition.verifyingIdentity'
            | translate }}...
          </h3>
          <div *ngIf="!faceVerificationLoading && !!faceVerification"
               layout="row"
               layout-align="space-between center">
            <span>{{ 'faceRecognition.confidence' | translate }}</span>
            <div layout="column"
                 layout-align="start"
                 flex
                 [ngStyle]="{ 'margin-right': '10px', 'margin-left': '10px' }">
            <span matTooltip="{{
                    'faceRecognition.minThreshold' | translate
                  }}"
                  matTooltipPosition="above"
                  [ngStyle]="{ 'left': '49%', 'position': 'absolute', 'z-index': 1, 'font-weight': 'bolder', 'font-size': '12px', 'color': '#7f7f7f'  }">
              |
            </span>
              <div *ngIf="!faceVerificationLoading"
                   [ngClass]="{'verified-identity': faceVerification.verified, 'non-verified-identity': !faceVerification.verified }">
                <mat-progress-bar mode="determinate"
                                  value="{{ faceVerification.confidence }}"></mat-progress-bar>
              </div>
            </div>
            <div layout="column">
            <span class="text-upper">{{ (faceVerification.verified ? 'faceRecognition.verified' :
              'faceRecognition.nonVerified') | translate }}</span>
              <span>{{ faceVerification.created_at | date: 'dd.MM.yyyy' }}</span>
            </div>
          </div>
          <span *ngIf="!faceVerificationLoading && !faceVerification && (idCardFront.image !== UNAVAILABLE_IMAGE)"
                layout="row"
                layout-align="start center"
                class="pad text-center">{{ 'faceRecognition.verifyingIdentityError' | translate }}
            <button matTooltip="{{ 'global.refresh' | translate }}"
                    mat-icon-button
                    (click)="verifyUserIdentity()">
            <mat-icon>refresh</mat-icon>
          </button>
        </span>
          <h3 *ngIf="idCardFront?.image == UNAVAILABLE_IMAGE"
              class="pad text-center">{{
            'faceRecognition.noIdFrontUploaded' | translate }}
          </h3>
          <!-- <mat-progress-bar *ngIf="faceVerificationLoading" mode="query"></mat-progress-bar> -->
          <!-- </ng-template> -->
        </mat-card-content>
        <!-- <ng-template tdLoading [tdLoadingUntil]="!faceVerificationLoading">
          <mat-card-content [style.height.px]="100">
          </mat-card-content>
        </ng-template> -->
      </mat-card>
      <!-- <mat-card [ngStyle]="{ background: faceVerificationLoading  ? '#3f51b5' : (faceVerification.verified ? '#2ea76c' : '#d32f2f') }">
        <mat-card-title
            class="identity-verification-title">
          <div *ngIf="!faceVerificationLoading" layout="row" layout-align="space-between center">
            <span class="identity-verification-title-text">{{ faceVerification.verified ? 'ПОТВЪРДЕНА САМОЛИЧНОСТ' : 'НЕПОТВЪРДЕНА САМОЛИЧНОСТ' }}</span>
            <mat-icon>{{ faceVerification.verified ? 'verified_user' : 'cancel' }}</mat-icon>
          </div>
          <span *ngIf="faceVerificationLoading" class="identity-verification-title-text">ПРОВЕРКА НА САМОЛИЧНОСТ...</span>
        </mat-card-title>
        <mat-card-content class="identity-verificatiion-card-content">
          <div layout="row" layout-align="space-between center">
              <span>Самоличност</span>
              <div layout="column">
                <span>{{ faceVerification.verified ? 'ПОТВЪРДЕНА' : 'НЕПОТВЪРДЕНА' }}</span>
                <span>{{ faceVerification.created_at | date: 'dd.MM.yyyy' }}</span>
              </div>
              <div layout="row" layout-align="start" *ngIf="faceVerificationLoading">
                <span>Проверка на самоличност...</span>
              </div>
            </div>
        </mat-card-content>
      </mat-card> -->
    </div>
    <div *ngIf="isReport && reportData?.data !== null">
      <itfg-mi-card [isReport]="isReport"
                    [isClientDataMatch]="isClientDataMatch"
                    [civilId]="client?.civilId"
                    [idCardNumber]="client?.idCardNumber"
                    [reportData]="reportData"
                    [clientReportData]="clientReportData"
                    (scrollToClientMapper)="scrollToClientMapper()">
      </itfg-mi-card>
    </div>
    <div #idCardImagesElement
         flex
         layout="row"
         layout-align="space-around start"
         *ngxPermissionsOnly="'USER_ID_DATA_READ'">
      <mat-card appearance="outlined"
                flex="33">
        <mat-card-title class="text-center">
        </mat-card-title>
        <mat-card-subtitle>
        </mat-card-subtitle>
        <mat-card-content>
          <div itfgZoomImage
               [url]="idCardSelfie.url"
               class="height-300"
               flex
               layout="row"
               layout-align="center center"
               *ngIf="idCardSelfie && idCardSelfie.image">
            <img [src]="idCardSelfie.image"
                 alt="idCardSelfieImage"
                 class="height-300"/>
          </div>
          <div class="name-overflow"
               *ngIf="idCardSelfie && idCardSelfie.image">{{idCardSelfie.name}}
          </div>

          <itfg-id-card-image-list [idCardList]="idCardSelfieList"
                                   [clientId]="client?.id"
                                   [selectedImageId]="idCardSelfie?.id"
                                   [idCardImageType]="IdCardImageTypes.SELFIE"
                                   (imageChange)="idCardImageChange($event)"
                                   (imageDelete)="idCardImageDelete($event)"
                                   (imageSave)="idCardImageSave($event)"></itfg-id-card-image-list>
        </mat-card-content>
      </mat-card>
      <mat-card appearance="outlined"
                flex="33">
        <mat-card-title class="text-center">
        </mat-card-title>
        <mat-card-subtitle>
        </mat-card-subtitle>
        <mat-card-content>
          <div itfgZoomImage
               [url]="idCardFront.url"
               class="height-300"
               flex
               layout="row"
               layout-align="center center"
               *ngIf="idCardFront && idCardFront.image">
            <img [src]="idCardFront.image"
                 alt="idCardFrontImage"
                 class="height-300"/>
          </div>
          <div class="name-overflow"
               *ngIf="idCardFront && idCardFront.image">{{idCardFront.name}}
          </div>

          <itfg-id-card-image-list [idCardList]="idCardFrontList"
                                   [selectedImageId]="idCardFront?.id"
                                   [clientId]="client?.id"
                                   [idCardImageType]="IdCardImageTypes.ID_FRONT"
                                   (imageChange)="idCardImageChange($event)"
                                   (imageDelete)="idCardImageDelete($event)"
                                   (imageSave)="idCardImageSave($event)"></itfg-id-card-image-list>
        </mat-card-content>
      </mat-card>
      <mat-card appearance="outlined"
                flex="33">
        <mat-card-title class="text-center">
        </mat-card-title>
        <mat-card-content>
          <div flex
               class="height-300"
               [url]="idCardBack.url"
               itfgZoomImage
               layout="row"
               layout-align="center center"
               *ngIf="idCardBack && idCardBack.image">
            <img [src]="idCardBack.image"
                 alt="idCardBackImage"
                 class="height-300"/>
          </div>
          <div class="name-overflow"
               *ngIf="idCardBack && idCardBack.image">{{idCardBack.name}}</div>
          <itfg-id-card-image-list [idCardList]="idCardBackList"
                                   [selectedImageId]="idCardBack?.id"
                                   [clientId]="client?.id"
                                   [idCardImageType]="IdCardImageTypes.ID_BACK"
                                   (imageChange)="idCardImageChange($event)"
                                   (imageDelete)="idCardImageDelete($event)"
                                   (imageSave)="idCardImageSave($event)"></itfg-id-card-image-list>
        </mat-card-content>
      </mat-card>

    </div>
  </div>


  <div #clientMapperElement
       flex="100"
       *ngxPermissionsOnly="'USER_ID_DATA_READ'">
    <itfg-update-client (dataChanged)="clientDataChanged($event)"
                        [INPUT_CLIENT_MAPPER]="CLIENT_MAPPER"
                        [inputClient]="client"
                        [inputClientData]="clientReportData"
                        [clientDataType]="reportData?.type"></itfg-update-client>
  </div>
  <h4 *ngIf="!client?.civilId && client?.id"
      [translate]="'credits.noCivilId'"
      flex
      layout="row"
      layout-align="start center">
    The Client doesn't have civil id!
  </h4>
</div>
